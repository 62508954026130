<div class="row">
  <div class="col-lg-5">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="header-image-option"></div>
        <mat-card-title>{{'options.' + option?.titre | translate}}</mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src='{{ "../../assets/img/" + option?.titre + ".jpg" }}'
        alt="Photo of {{'options.' + option?.titre | translate}}">
      <mat-card-content>
        <p style="white-space: pre-line; font-size: 17px;" [innerHTML]="'options.' + option?.description | translate"></p>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-lg-7">
    <div class="encart-description">
      <mat-list>
        <mat-list-item *ngFor="let element of option?.elements">
          <div mat-line><strong>{{'options.' + element?.titre | translate}}<span *ngIf="element?.prix">&nbsp;-&nbsp;{{element?.prix}}&nbsp;€</span></strong></div>
          <div mat-line [innerHTML]="'options.' + element?.description | translate"></div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
<p *ngIf="option?.informationsComplementaires">{{"options." + option?.informationsComplementaires | translate}}</p>
