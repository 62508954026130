<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"contact.Titre" | translate}}
  </h1>

  <div class="rte">
    <h2 style="text-align: center;">{{"contact.ccc" | translate}}</h2>
    <p style="text-align: center;">{{'Siegesocial' | translate}} 49 Allée du rivage 33950 Lège-Cap-Ferret
      <br>{{'Tel' | translate}} <a href="tel:0609064579">+33 (0)6.09.06.45.79</a>
      <br>{{'E-mail' | translate}} <a href="mailto:capferretcruiseconfortcompany@gmail.com">capferretcruiseconfortcompany@gmail.com</a>
    </p>
    <div class="contact">
      <form [formGroup]="contactForm" (submit)="onSubmit()">
        <mat-form-field appearance="outline">
          <mat-label>{{'Nom' | translate}}</mat-label>
          <input class="input-contact" [formControl]="labelControl" matInput placeholder="Etienne Martin" required>
          <mat-error *ngIf="labelControl.invalid">{{getMessage(labelControl) | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'E-mail' | translate}}</mat-label>
          <input class="input-contact" [formControl]="emailControl" matInput placeholder="e.martin@mail.fr" required>
          <mat-error *ngIf="emailControl.invalid" [innerHTML]="getMessage(emailControl) | translate"></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'Telephone' | translate}}</mat-label>
          <mat-icon matSuffix>phone</mat-icon>
          <input class="input-contact" [formControl]="telephoneControl" matInput placeholder="ex: 0123456789">
        </mat-form-field>
        <mat-form-field appearance="outline" class="sam_types__textfield__cadre_description">
          <mat-label>{{'Message' | translate}}<span class="rouge-cccc">&nbsp;*</span></mat-label>
          <textarea  matInput
                     [formControl]="messageControl"
                     cdkTextareaAutosize
                     #autosize="cdkTextareaAutosize"
                     cdkAutosizeMinRows="2"
                     cdkAutosizeMaxRows="5"></textarea>
          <mat-error *ngIf="messageControl.invalid">{{getMessage(messageControl) | translate}}</mat-error>
        </mat-form-field>
          <button mat-button [disabled]="this.disabled || !this.contactForm.valid" type="submit" class="btn btn-cccc-color">
            <span matTooltip="{{'error.tooltip' | translate}}" [matTooltipDisabled]="!(this.disabled || !this.contactForm.valid)" *ngIf="!submitted">
              {{'EnvoyerMessage' | translate}}
            </span>
          </button>
        <div *ngIf="submitted" class="message_sent">
          {{'MessageEnvoye' | translate}}
        </div>
        <div *ngIf="error" class="message_sent">
          {{'MessageNonEnvoye' | translate}}
        </div>
        <br/>
        <p>
          {{'CopieMail' | translate}}
        </p>
      </form>
    </div>
  </div>
</div>
