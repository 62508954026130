export class langUtils {
  private static getNavigatorLanguage(): string {
    return navigator.language in languageEnum ? navigator.language : 'fr';
  }

  static getDefaultLanguage(): string {
    return window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : this.getNavigatorLanguage();
  }
}

export enum languageEnum {
  FRANCAIS = 'fr',
  ANGLAIS = 'en'
}
