import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AccueilComponent} from "./accueil/accueil.component";
import {ContactComponent} from "./contact/contact.component";
import {PlanAccesComponent} from "./plan-acces/plan-acces.component";
import {FormulesComponent} from "./formules/formules.component";
import {EventsComponent} from "./events/events.component";
import {BoatComponent} from "./boat/boat.component";
import {OffrePromotionnelleComponent} from "./offre-promotionnelle/offre-promotionnelle.component";
import {OptionsComponent} from "./options/options.component";
import {ReservationComponent} from "./reservation/reservation.component";
import {MentionsLegalesComponent} from "./mentions-legales/mentions-legales.component";
import {CgvComponent} from "./cgv/cgv.component";
import {AbordComponent} from "./abord/abord.component";
import {ReservationResultComponent} from "./reservation-result/reservation-result.component";

export const appRouteList: Routes = [
  {
    path: 'home',
    component: AccueilComponent
  },
  {
    path: 'plan-d-acces',
    component: PlanAccesComponent
  },
  {
    path: 'evenements',
    component: EventsComponent
  },
  {
    path: 'options',
    component: OptionsComponent
  },
  {
    path: 'balades',
    component: FormulesComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'reserver/:id',
    component: ReservationComponent
  },
  {
    path: 'reserver',
    component: ReservationComponent
  },
  {
    path: 'reservation/:result',
    component: ReservationResultComponent
  },
  {
    path: 'boat',
    component: BoatComponent
  },
  {
    path: 'cgv',
    component: CgvComponent
  },
  {
    path: 'mentions-legales',
    component: MentionsLegalesComponent
  },
  {
    path: 'abord',
    component: AbordComponent
  },
  {
    path: 'promo',
    component: OffrePromotionnelleComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forRoot(appRouteList, {scrollPositionRestoration: 'enabled'})
  ]
})
export class AppRoutingModule {
}
