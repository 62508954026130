export interface ListeElements {
  titre: string;
  elements: ListeDeChoixPayant[];
  description: string;
  informationsComplementaires?: string;
}

export interface ListeElementsCheckbox {
  titre: string;
  elements: ListeDeChoixPayantCheckbox[];
  description: string;
  informationsComplementaires?: string;
}

export interface ListeDeChoixPayant {
  titre: string;
  description: string;
  prix?: number;
}

export interface PrestationComplete {
  titre: string;
  duree: string;
  circuit: string;
  condition?: string;
  prix?: string;
  prixAdulte?: number;
  prixEnfant?: number;
  prixBase?: number,
  prixHeureSupp?: number;
  nbPlace?: string;
  reservation?: boolean;
  carousel?: CarouselElement[];
}

export interface CarouselElement {
  id: number;
  href?: string;
  alt: string;
  titre: string;
}

export interface MailInformation {
  user?: string;
  mail?: string;
  tel?: string;
  message?: string;
}

export interface MailReservation extends Reservation {
  user?: string;
  mail?: string;
  telephone?: string;
  titrePrestation?: string;
  tarifPrestation?: string;
  listOption?: string;
  tarifOption?: string;
  prixTotal?: string;
}

export interface ListeDeChoixPayantCheckbox extends ListeDeChoixPayant{
  checked: boolean;
}

export interface GroupElement {
  name: string;
  choices: PrestationComplete[];
}

export interface OptionsSupplementairesReservation {
  idOption: string;
  titreOption?: string;
  nbOption: number;
  priceOption: number;
}

export interface UserInformation {
  name: string;
  email: string;
  telephone: string;
  prix?: number;
}

export interface Reservation {
  idPrestation: string;
  titrePrestation?: string;
  nbAdulte: number;
  nbEnfant?: number;
  nbEnfant3ans?: number;
  nbHeureSupp?: number;
  date: string;
  dateDebut?: string;
  dateFin?: string;
  optionsSupplementaires: OptionsSupplementairesReservation[];
}

export interface DatesPossible {
  heuresAProposer: Date[];
}

export interface urlPayment {
  url: string;
}

export enum TitrePrestation {
  TitreInevitable = 'L\'Inévitable',
  TitreExquise = 'L\'Exquise',
  TitreLibre = 'La Libre Découverte',
  TitreSunset = 'La Sunset',
  TitreLeyre = 'La Leyre',
  TitreSommeliere = 'La Sommelière',
  TitreFestive = 'La Festive',
  TitreStudieuse = 'La Studieuse'
}

export enum TitreOption {
  TitreCitadine = 'Citadine(s)',
  TitrePanierPrimeur = 'Panier(s) du primeur',
  TitreLocale = 'Locale(s)',
  TitrePetiteLocale = 'Petite(s) locale(s)',
  RougeMachorre = 'Château Machorre rouge',
  RougePiron = 'Château Piron rouge',
  BlancPiron = 'Château Piron blanc',
  BlancFontenille = 'Château de Fontenille blanc',
  RoseGoujonne = 'Domaine la Goujonne rosé',
  RoseNavicelle = 'Saint Maur rosé',
  Champagne = 'Champagne',
  Coca = 'Coca-cola',
  CocaZero = 'Coca-cola Zéro',
  Biere = 'Bière',
  JusOrange = 'Jus d\'orange',
  JusPomme = 'Jus de pomme'
}
