<mat-card class="contourCard">
  {{'TotalAPayer' | translate}} <div class="rouge-cccc" style="margin-bottom: 5px;"><strong>{{totalAPayer}}&nbsp;€</strong></div>
  <div [formGroup]="formGroup">
    <label class="label-information">{{ 'paiement.informations' | translate }}</label>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.nameReservation' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardName"
            required>
          <mat-error *ngIf="cardName.invalid" class="errorMessage">{{'error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row second">
      <div class="col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.email' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardEmail"
            placeholder="prenom@mail.com"
            required>
          <mat-error *ngIf="cardEmail.invalid" class="errorMessage">{{getErrorMessage(cardEmail) | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.telephone' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardTelephone"
            [placeholder]="'paiement.telephone_placeholder' | translate"
            (keypress)="omitNegativeSignAndTrim($event);"
            required>
          <mat-error *ngIf="cardTelephone.invalid" class="errorMessage">{{getErrorMessage(cardTelephone) | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <button mat-button class="btn btn-cccc-color" [disabled]="formGroup.invalid || disabledSubmitButton" type="submit" (click)="pay()" *ngIf="!succeed" style="margin-top: 20px;">
      {{'Valider' | translate}}
    </button>
    <div *ngIf="isError" class="message_sent">
      {{errorMessage}}
    </div>
    <div *ngIf="succeed" class="message_sent">
      {{'SucceedMessage' | translate}}
    </div>
  </div>
</mat-card>
