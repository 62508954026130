import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { ContactComponent } from './contact/contact.component';
import { AccueilComponent } from './accueil/accueil.component';
import {AppRoutingModule} from "./app-routing.module";
import { FooterComponentComponent } from './footer-component/footer-component.component';
import { NavigationComponentComponent } from './navigation-component/navigation-component.component';
import { PlanAccesComponent } from './plan-acces/plan-acces.component';
import { FormulesComponent } from './formules/formules.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { EventsComponent } from './events/events.component';
import { BoatComponent } from './boat/boat.component';
import { OptionsComponent } from './options/options.component';
import { CarouselComponent } from './carousel/carousel.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CarouselModule} from "ngx-owl-carousel-o";
import { MapComponent } from './map/map.component';
import { ReservationComponent } from './reservation/reservation.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { CgvComponent } from './cgv/cgv.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {FlatpickrModule} from "angularx-flatpickr";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDividerModule} from "@angular/material/divider";
import {MatListModule} from "@angular/material/list";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import { CarouselMatCardComponent } from './carousel-mat-card/carousel-mat-card.component';
import { CadrePrestationsComponent } from './cadre-prestations/cadre-prestations.component';
import { CadreOptionsComponent } from './cadre-options/cadre-options.component';
import localeFr from '@angular/common/locales/fr';
import {DatePipe, registerLocaleData} from "@angular/common";
import {NgxUiLoaderConfig, NgxUiLoaderModule} from "ngx-ui-loader";
import { ReservationSansPaiementsComponent } from './reservation-sans-paiements/reservation-sans-paiements.component';
import {NumberFilterDirective} from "./directives/number-filter.directive";
import { AbordComponent } from './abord/abord.component';
import { GalleryComponent } from './gallery/gallery.component';
import {PaiementsStripeComponent} from "./paiements-stripe/paiements-stripe.component";
import {NgxGalleryModule} from "@kolkov/ngx-gallery";
import { ReservationAvecPaiementsComponent } from './reservation-avec-paiements/reservation-avec-paiements.component';
import { ReservationResultComponent } from './reservation-result/reservation-result.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OffrePromotionnelleComponent } from './offre-promotionnelle/offre-promotionnelle.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
registerLocaleData(localeFr);

const ngxUiLoaderConfiguration: NgxUiLoaderConfig =
  {
    bgsColor: "red",
    bgsOpacity: 0.5,
    bgsPosition: "bottom-right",
    bgsSize: 40,
    bgsType: "ball-spin-clockwise",
    blur: 1,
    fastFadeOut: true,
    fgsColor: "#330099",
    fgsPosition: "center-center",
    fgsSize: 40,
    fgsType: "ball-spin-clockwise-fade-rotating",
    gap: 24,
    logoPosition: "center-center",
    logoSize: 120,
    masterLoaderId: "master",
    overlayBorderRadius: "15",
    overlayColor: "rgba(40,40,40,0.69)",
    pbColor: "red",
    pbDirection: "ltr",
    pbThickness: 3,
    hasProgressBar: false,
    textColor: "#FFFFFF",
    textPosition: "center-center"
}

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    AccueilComponent,
    FooterComponentComponent,
    NavigationComponentComponent,
    PlanAccesComponent,
    FormulesComponent,
    EventsComponent,
    BoatComponent,
    OptionsComponent,
    CarouselComponent,
    MapComponent,
    ReservationComponent,
    MentionsLegalesComponent,
    CgvComponent,
    CarouselMatCardComponent,
    CadrePrestationsComponent,
    CadreOptionsComponent,
    PaiementsStripeComponent,
    ReservationSansPaiementsComponent,
    NumberFilterDirective,
    AbordComponent,
    GalleryComponent,
    ReservationAvecPaiementsComponent,
    ReservationResultComponent,
    OffrePromotionnelleComponent
  ],
  imports: [
    BrowserModule,
    FlatpickrModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CarouselModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxGalleryModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfiguration),
    NgbModule
  ],
  providers: [
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: LOCALE_ID, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
