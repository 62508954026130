import {Component, Input} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {CarouselElement} from "../model/element.model";

@Component({
  selector: 'app-carousel-mat-card',
  templateUrl: './carousel-mat-card.component.html',
  styleUrls: ['./carousel-mat-card.component.css']
})
export class CarouselMatCardComponent {

  @Input() carouselElement: CarouselElement[] = [];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 100,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
    },
    nav: false
  };

}
