<div class="container encart" *ngIf="options$ | async as options">
  <div class="h1 titre-encart">
    {{"options.OptionsDegustations" | translate}}
  </div>

  <div id="Assiette" class="anchor-option">
    <app-cadre-options [option]="options[0]"></app-cadre-options>
  </div>
  <hr/>
  <div id="Vins" class="anchor-option">
    <app-cadre-options [option]="options[1]"></app-cadre-options>
  </div>
  <hr/>
  <div id="Softs" class="anchor-option">
    <app-cadre-options [option]="options[2]"></app-cadre-options>
  </div>
  <hr/>
</div>
