import { Directive, OnInit, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { coerceNumberProperty } from '@angular/cdk/coercion';

@Directive({
  selector: '[inputNumber]'
})
export class NumberFilterDirective implements OnInit {
  private static NUMBER_REGEXP = /^\d*$/;
  constructor(@Self() private control: NgControl) {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe(val => {
      if (val) {
        const filteredValue: number = this.filterValue(val);
        if (filteredValue !== val) {
          this.control.control.setValue(filteredValue);
        }
      }
    });
  }

  private filterValue(val: string): number {
    let filteredValue = '';
    for (const char of '' + val) {
      if (char.charAt(0).match(NumberFilterDirective.NUMBER_REGEXP)) {
        filteredValue = filteredValue + char.charAt(0);
      }
    }
    return filteredValue || filteredValue === '0' ? coerceNumberProperty(filteredValue) : null;
  }
}
