import { Component } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-offre-promotionnelle',
  templateUrl: './offre-promotionnelle.component.html',
  styleUrls: ['./offre-promotionnelle.component.css']
})
export class OffrePromotionnelleComponent {

  promoForm: UntypedFormGroup;

  labelControl = new UntypedFormControl('', [Validators.required]);
  emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  adulteControl = new UntypedFormControl(0, [Validators.required, Validators.max(12)]);
  telephoneControl = new UntypedFormControl('');

  constructor() { }

  onSubmit() {
    console.log('submit of me');
  }

  autoCalculate() {
    console.log("atta gros");
  }

  getMessage(formControl: UntypedFormControl) {
      if (formControl.hasError('required')) {
        return 'error.required';
      }
      if (formControl.hasError('email')) {
        return 'error.email';
      }
      if (formControl.hasError('max')) {
        return 'error.max';
      }
    }
}
