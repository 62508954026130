<div  class="banner"></div>
<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"Mentionslégales" | translate}}
  </h1>

  <hr>

  <div class="rte">
    <p>
      <br>
      Le présent site « Cap Ferret Cruise Confort Company » est la propriété de Nicolas Briau&nbsp;:
      <br>
      •&nbsp;&nbsp; &nbsp;{{'SiegesocialML' | translate}}&nbsp;: 49 Allée du rivage 33950 Lège-Cap-Ferret<br>
      •&nbsp;&nbsp; &nbsp;{{'TelML' | translate}}&nbsp;: <a href="tel:0609064579">+33 (0)6.09.06.45.79</a><br>
      •&nbsp;&nbsp; &nbsp;{{'E-mailML' | translate}} : <a href="mailto:capferretcruiseconfortcompany@gmail.com">capferretcruiseconfortcompany@gmail.com</a><br>
      •&nbsp;&nbsp; &nbsp;{{'FormeJuridiqueML' | translate}}&nbsp;: Société par actions simplifiées<br>
      •&nbsp;&nbsp; &nbsp;SIRET&nbsp;: 89046785500015<br>
      •&nbsp;&nbsp; &nbsp;RCS&nbsp;: Bordeaux B 890 467 855<br>
      Pour de plus amples informations&nbsp;:&nbsp;<a target="_blank" rel="noopener" href="https://www.societe.com/societe/cap-ferret-cruise-confort-company-890467855.html">www.societe.com</a>

      <br>
      <br>
      <strong>1. Edition du site web</strong>
      <br>
      <br>
      Responsable de publication : Nicolas Briau<br>Animation éditoriale, gestion et mise à jour : Pierre Martin-Villepou<br>Création du portail touristique : Pierre Martin-Villepou<br>Logiciel de gestion de contenu web : Angular11<br>Hébergeur : OVH
      <br>
      <br>
      <strong>2. Informatiques et libertés</strong>
      <br>
      <br>
      Le site <a href="www.capferretcruise.com">www.capferretcruise.com</a>&nbsp;a fait l’objet d’une déclaration des traitements automatisés mis en œuvre dans le cadre des sites Internet auprès de la Commission nationale de l’informatique et des libertés (CNIL) en application des articles 15 et 16 de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés. Vous disposez également d’un droit général d’accès, de rectification et de suppression de l’ensemble des données personnelles vous concernant, en nous contactant par courriel à l’adresse suivante : capferretcruiseconfortcompany@gmail.com
      <br>
      <br>
      <strong>3. Politique des données</strong>
      <br>
      <br>
      La présente mention a pour objet de satisfaire à l'obligation d'information de CAP FERRET CRUISE CONFORT COMPANY et de formaliser les droits et les obligations des personnes concernées au regard du traitement de leurs données à caractère personnel, conformément notamment au Règlement Général sur la Protection des Données (EU) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 (ci-après le RGPD) et aux recommandations de la Commission Nationale de l'Informatique et des Libertés (ci-après la CNIL).
      <br>
      Sur notre site, par le biais de nos formulaires en ligne, vous allez être amené à nous fournir des informations dont les champs obligatoires seront marqués d'un astérisque. Le refus de fournir d'autres données, non indispensables à la commande, n'entraîne aucune conséquence pour le Client. Ces informations, tel que le nom, le numéro de téléphone ou l'adresse email n'aura pour but que de tenir informé le Client de toutes informations utiles pour sa réservation et seront supprimées dès lors que la date de réservation sera dépassée. De plus aucun fichier appelé "Cookie" n'est utilisé.
      <br>
      L’accès au site et son utilisation sont réservés à un usage strictement personnel. CAP FERRET CRUISE CONFORT COMPANY s’engage à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l’envoi de courriers électroniques non sollicités. CAP FERRET CRUISE CONFORT COMPANY prend toutes les précautions pour préserver la sécurité de vos données.
    </p>
  </div>
</div>
