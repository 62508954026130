import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-reservation-result',
  templateUrl: './reservation-result.component.html',
  styleUrls: ['./reservation-result.component.css']
})
export class ReservationResultComponent {

  success: boolean;
  idTracked: string;

  constructor(private route: ActivatedRoute) {
    this.success = this.route.snapshot.params.result === 'success';
    this.idTracked = this.route.snapshot.queryParams.tracker;
  }

}
