<div class="container encart">
  <div class="h1 logoCenter">
    <img src="assets/img/accueil.png"/>
  </div>

  <div class="row">
    <div class="col-lg-6">
      <div class="encart-description">
        <strong class="rouge-cccc"><u>Les Sodas :</u></strong>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Coca-cola zéro</strong></div><div class="rouge-cccc"><strong>2.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 33cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Coca-cola</strong></div><div class="rouge-cccc"><strong>2.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Bouteille de 25cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Coca-cola</strong></div><div class="rouge-cccc"><strong>1.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 15cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Orangina</strong></div><div class="rouge-cccc"><strong>1.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 15cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Schweppes agrumes</strong></div><div class="rouge-cccc"><strong>1.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 15cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Schweppes tonic</strong></div><div class="rouge-cccc"><strong>1.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 15cl</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Ice tea pêche</strong></div><div class="rouge-cccc"><strong>1.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette de 15cl</div>
        </div>
        <div class="style-specific"><strong class="rouge-cccc"><u>Les Eaux :</u></strong></div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Eau plate 50cl/1L</strong></div><div class="rouge-cccc"><strong>1.00€/2.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Bouteille</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Eau gazeuse 50cl/1L</strong></div><div class="rouge-cccc"><strong>1.50€/3.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Bouteille</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="encart-description">
        <strong class="rouge-cccc"><u>Les Vins :</u></strong>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Château Piron rouge</strong></div><div class="rouge-cccc"><strong>18.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Bordeaux supérieur</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Château Piron blanc</strong></div><div class="rouge-cccc"><strong>18.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Bordeaux supérieur</div>
        </div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Domaine de Navicelle rosé</strong></div><div class="rouge-cccc"><strong>20.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Côtes de Provence, BIO</div>
        </div>
        <br/>
        <div class="style-specific"><strong class="rouge-cccc"><u>Champagne :</u></strong></div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Dame de Briau</strong></div><div class="rouge-cccc"><strong>50.00&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Brut</div>
        </div>
        <br/>
        <div class="style-specific"><strong class="rouge-cccc"><u>Bière :</u></strong></div>
        <div class="itemMenu">
          <div class="lineMenu">
            <div><strong>Heinekken</strong></div><div class="rouge-cccc"><strong>3.50&nbsp;€</strong></div>
          </div>
          <div class="subtitle">Canette 25cl</div>
        </div>
        <p class="spaceGap">&nbsp;</p>
      </div>
    </div>
  </div>
</div>
