<mat-card class="contourCard">
  {{'TotalAPayer' | translate}} <div class="rouge-cccc" style="margin-bottom: 5px;"><strong>{{totalAPayer}}&nbsp;€</strong></div>
  <div [formGroup]="stripe">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.name' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardName"
            required>
          <mat-error *ngIf="cardName.invalid">{{'error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <mat-card class="cardnumber">
      <!--<ngx-stripe-card
        [options]="cardOptions"
        [elementsOptions]="elementsOptions"
      ></ngx-stripe-card>-->
    </mat-card>
    <label class="label-information">{{ 'paiement.informations' | translate }}</label>
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.email' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardEmail"
            required>
          <mat-error *ngIf="cardEmail.invalid">{{'error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'paiement.telephone' | translate }}</mat-label>
          <input
            matInput
            class="card-name"
            [formControl]="cardTelephone"
            required>
          <mat-error *ngIf="cardTelephone.invalid">{{'error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <button mat-button class="btn btn-cccc-color" [disabled]="stripe.invalid || disabledSubmitButton" type="submit" (click)="pay()" *ngIf="!succeed">
      {{'Valider' | translate}}
    </button>
    <div *ngIf="isError" class="message_sent">
      {{errorMessage}}
    </div>
    <div *ngIf="succeed" class="message_sent">
      {{'SucceedMessage' | translate}}
    </div>
  </div>
</mat-card>
