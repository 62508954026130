<div class="container encart" *ngIf="events$ | async as events">
  <h1 class="h1 titre-encart">
    {{"prestations.Evenements" | translate}}
  </h1>

  <div id="TitreSommeliere" class="anchor">
    <app-cadre-prestations [prestation]="events[0]" [type]="'events'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreLeyre" class="anchor">
    <app-cadre-prestations [prestation]="events[1]" [type]="'events'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreFestive" class="anchor">
    <app-cadre-prestations [prestation]="events[2]" [type]="'events'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreStudieuse" class="anchor">
    <app-cadre-prestations [prestation]="events[3]" [type]="'events'"></app-cadre-prestations>
  </div>
  <hr/>
  <div class="contact-events">{{'options.Contact' | translate}}</div>
</div>
