<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"Histoire" | translate}}
  </h1>

  <h2>Le BATEAU</h2>
  <img src="./assets/img/bateauLarge.jpg" alt="Notre bateau" class="boat"/>
  <div class="rte">
    <p>
      Ce bateau est le fruit d’une belle rencontre avec Jérôme Lacaze, un professionnel reconnu de la construction navale. Son savoir-faire est de pouvoir allier tradition, modernité et personnalisation.
    </p>
    <p>
      J'avais pour ambition de faire partager la beauté du Bassin d’Arcachon par le biais de balades maritimes variées, confortables et sécurisées.
    </p>
    <p>
      Pour cela, il était nécessaire d'opter pour la construction d'un navire pleinement adapté aux caractéristiques nautiques du Bassin d’Arcachon (peu de tirant d’eau, échouage facile, stabilité en mer formée…).
    </p>
    <p>
      Dès lors, est né le <strong style="color: #990066">CAMILLE</strong>, premier prototype de 10 mètres du chantier naval LACAZE. Cette création artisanale de haute technicité offre une remarquable stabilité et un environnement de qualité pour les passagers.
    </p>
    <p>
      Ses performances de navigation sont des atouts indéniables pour aller à la rencontre de nos fabuleux paysages. Son aménagement intérieur a également été pensé pour pouvoir profiter d’instants conviviaux comme celui de déguster au cœur de cette mer intérieure des mets locaux accompagnés d&#39;excellents vins de propriété.
    </p>
  </div>


  <h2>Le MARIN</h2>
  <img src="./assets/img/marin.jpg" alt="Le Marin" class="boat"/>
  <div class="rte">
    <p>
      Je m’appelle <strong style="color: #990066">Nicolas BRIAU</strong> et je vis à Grand-Piquey. C’est l’un des 11 villages de la presqu’île de Lège Cap-Ferret. Depuis 5 générations ma famille réside entre la villa Les Hirondelles et la jetée. Site authentique, dont le poète Jean Cocteau a apprécié la quiétude entre 1917 et 1937.
    </p>
    <p>
      Passionné du Bassin d’Arcachon et de son histoire, j’aime faire partager mes connaissances et mes anecdotes sur l'évolution de ce magnifique territoire maritime et terrestre qui ne se visite pas mais se découvre
    </p>
  </div>

  <div id="cartes" class="anchor">
    <h2>Nos balades sur le bassin</h2>
    <h3><a href="balades#TitreInevitable">L'Inévitable&nbsp;:</a></h3>
    <img src="./assets/img/TitreInevitableCarte.jpg" alt="L'inévitable"/>
    <h3><a href="balades#TitreExquise">L'Exquise&nbsp;:</a></h3>
    <img src="./assets/img/TitreExquiseCarte.jpg" alt="L'exquise"/>
    <h3><a href="evenements#TitreLeyre">La traversée de la Leyre&nbsp;:</a></h3>
    <img src="./assets/img/TitreLeyreCarte.jpg" alt="La traversée de la Leyre"/>
    <br><br>
    <div class="rte">
      <p>Ou bien laisser libre cours à vos envies avec nos autres balades sur mesure, aussi bien au coucher du soleil avec la <a href="balades#TitreSunset">Sunset</a>,
      pour le plaisir avec la <a href="balades#TitreLibre">Libre Découverte</a> ou pour des évènements spéciaux avec la <a href="evenements#TitreStudieuse">Studieuse</a> ou la <a href="evenements#TitreStudieuse">Festive.</a>
      Vous pourrez également découvrir le bassin en dégustant des vins à votre convenance avec la <a href="evenements#TitreSommeliere">Sommelière</a>.
        N'hésitez pas à <a href="contact">nous contacter</a>.</p>
    </div>
  </div>

  <div id="photos">
    <h2>Photos du bateau</h2>
    <div class="container">
      <app-gallery class="centerElement"></app-gallery>
    </div>
  </div>

</div>
