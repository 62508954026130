<div  class="banner"></div>
<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"Conditionsventes" | translate}}
  </h1>

  <div class="rte">
    <p>
      <strong>CONDITIONS GENERALES DE VENTE DE CAP-FERRET CRUISE CONFORT COMPANY</strong>
      <br>
      <br>
      <strong>1. Préambule</strong>
      <br>Les présentes conditions de ventes sont applicables au transport maritime assuré par la société&nbsp;:&nbsp;Cap-Ferret Cruise Confort Company&nbsp;(CCCC),&nbsp;SIRET 89046785500015,APE 9329Z dont le siège social est établi&nbsp;:&nbsp;49, Allée du Rivage, 33950 LEGE-CAP FERRET.<br>Les présentes conditions générales s'appliquent à l'ensemble des prestations assurées par CCCC. Toutes clauses contraires ou complémentaires non contractuelles ou qui ne feraient pas l’objet de conditions particulières écrites ou d’un accord entre CCCC et le Client sont réputées caduques.<br>Ces Conditions Générales de Vente sont adressées au Client en même temps que le devis, pour lui permettre d’effectuer sa réservation. Toute réservation et/ou commande implique donc de la part du Client l’adhésion entière et sans réserve aux présentes conditions.<br>En cas de contradiction entre les dispositions figurant au devis signé par le Client et celles figurant aux présentes Conditions, les dispositions du devis prévalent.<br>La réservation et/ou la commande de prestations est réservée au Client ayant pris connaissance des présentes conditions de vente dans leur intégralité, et les ayant acceptées sans réserve. Dès lors, la prise de commande entraîne l'entière adhésion aux présentes conditions générales de vente et l'acceptation sans réserve de l'intégralité de leurs dispositions. Dans le but de satisfaire le Client, CCCC pourra être amené à faire évoluer les présentes conditions générales ainsi que sa gamme de produits.
      <br>
      <br>
      <strong>2. Modalités de réservation et d'achat des prestations proposées</strong>
      <br>
      <br>
      <strong>2.1 Disponibilités des prestations</strong>
      <br>Les réservations pour l’ensemble des prestations sont possibles en fonction des disponibilités. Pour certaines dates spéciales, notamment pour nos évènements, ces disponibilités peuvent faire l’objet de conditions spécifiques.
      <br>
      <br>
      <strong>2.2 Prix de vente</strong>
      <br>Les prix sont mentionnés en euros, hors taxes ou toutes taxes comprises. Les prix facturés sont ceux en vigueur au jour de la commande. Il appartient au Client d’apprécier avant la validation de la commande si le prix lui convient.<br>Aucune contestation concernant le prix ne pourra être prise en considération ultérieurement.<br>Ils sont assujettis aux taux de TVA en vigueur applicables le jour de la prestation. Toutes taxes ou charges nouvelles qui pourraient être créées, ou toute modification des charges ou taxes actuelles entraîneraient automatiquement le réajustement des prix.<br>La variation des conditions économiques peut entraîner une révision des prix dans les limites légales prévues. Les coûts du transport lié au coût du carburant seront répercutés sur les prix. Toutefois, au cours des 15 jours qui précèdent la date de la prestation, le prix fixé ne pourra plus faire l'objet de changement.
      <br>
      <br>
      <strong>2.3 La réservation</strong>
      <br>La réservation d’une prestation est sans frais. Elle est confirmée après le versement d’arrhes ou le paiement du montant total de la prestation ou aux conditions spécifiques décrites sur le site internet.<br>Si le paiement ou l’encaissement de la réservation n’ont pu être réalisés dans les conditions prévues, CCCC considère qu’il s’agit d’une annulation du fait du client.<br>Il est recommandé d’être présent à l’embarquement 10 minutes avant le départ du navire.<br>L’embarquement de nouveaux passagers est possible au dernier moment en fonction des places disponibles.
      <br>
      <br>
      <strong>2.4 Modalités particulières de commande et d'achat sur internet</strong>
      <br>Pour toute commande en ligne des « balades », « options », « évènements » et toutes prestations hors devis,  le montant du versement demandé est à effectuer selon les modalités définies lors de la prise de commande. Le client devra payer l’intégralité de la commande immédiatement par virement en ligne.
      <br>
      <br><i>2.4.a Les conditions communes</i>
      <br>Les présentes modalités particulières précisent les conditions communes présentées ci-dessus et concernent le site suivant : www.capferretcruise.com.<br>Après avoir choisi une prestation et/ou le service pour lequel il souhaite effectuer une réservation, le Client manifestera son acceptation des présentes Conditions Générales de Vente. La commande ne sera définitivement enregistrée qu’après la dernière validation. Un écran présentant le résumé de la réservation sera disponible.<br>Pour passer commande sur le site, le Client devra remplir, selon les indications fournies en ligne, un formulaire dans lequel devront figurer ses coordonnées. Les informations obligatoires sont signalées sur le site. Le système assure l’envoi des documents mais pas leur réception. En cas de non-réception, il appartient au client d’en informer le prestataire. En aucun cas la responsabilité de CCCC, ne pourra être retenue si le Client ne reçoit pas de confirmation de la commande en raison d’une erreur de son fait dans la saisie de ses coordonnées.
      <br>
      <br><i>2.4.b Les capacités du contractant</i>
      <br>Pour commander le Client doit être âgé d’au moins 18 ans, être capable juridiquement de contracter et utiliser ces sites conformément aux présentes Conditions Générales. Le Client est financièrement responsable de l’utilisation du site www.capferretcruise.com tant en son nom que pour le compte de tiers, y compris des mineurs, sauf à démontrer une utilisation frauduleuse ne résultant d’aucune faute ni négligence de sa part. Le Client garantit également la véracité et l’exactitude des informations fournies par lui-même ou tout autre tiers utilisant ses données sur le site. Une utilisation frauduleuse ou qui contreviendrait aux présentes Conditions Générales, du service de réservation du site, pourra entraîner le refus par CCCC, à tout moment, de l’accès aux services proposés sur Internet.
      <br>
      <br><i>2.4.c Accès aux informations concernant la commande du Client</i>
      <br>Le Client peut, à tout moment, consulter les informations concernant le détail de sa réservation en contactant le service commercial par mail ou par téléphone.
      <br>
      <br><i>2.4.d Modification / Annulation de la commande / Frais d’annulation</i>
      <br>Aucune modification ne pourra être réalisée en ligne. Pour toute modification, le Client doit contacter CCCC par téléphone. Le numéro de téléphone est indiqué au Client dans le mail de confirmation. Une fois la demande d’annulation enregistrée, celle-ci est traitée dans les meilleurs délais par CCCC. En cas d’annulation du fait du Client ou du fait de CCCC, il sera fait application des conditions générales d’annulation spécifiées au début de cet article ci-dessus.
      <br>
      <br><i>2.4.e Conditions de paiement pour les ventes en ligne</i>
      <br>Pour toutes prestations ne faisant pas l’objet d’un devis : l’intégralité du montant de la commande du client est immédiatement payée par virement en ligne Les prestations supplémentaires commandées le jour de la prestation seront à régler en intégralité au moment de la commande.
      <br>
      <br><i>2.4.f Validité des prestations achetées en ligne</i>
      <br>Toutes les prestations sont valables pour la date de réservation choisie par le Client. Elles ne sont pas reportables. Toutes les prestations type « option » doivent être consommées en une seule fois pour la ou les dates de réservation choisie(s) par le Client.
      <br>
      <br><i>2.4.g Sécurisation des paiements en ligne</i>
      <br>Le paiement en ligne par carte bancaire s’effectue dans un environnement entièrement sécurisé. Les cartes bancaires acceptées pour le paiement sont les suivantes : Visa / Mastercard.<br>La commande validée par le Client ne sera considérée comme effective que lorsque les centres de paiement auront donné leur accord. En cas de refus des dits centres, le Client est immédiatement informé par un message à l’écran dans lequel il est indiqué l’échec de la transaction. Le Client devra alors contacter le service client afin de trouver une solution de paiement.
      <br>
      <br><i>2.4.h Responsabilités et garanties pour l'utilisation du site www.capferretcruise.com</i>
      <br>CCCC ne garantit pas que le sitewww.capferretcruise.com sera exempt d’anomalies, d’erreurs ou de bugs, ni que celles-ci pourront être corrigées, ni que le site fonctionnera sans interruption ou pannes, ni encore qu’il est compatible avec un matériel ou une configuration particulière autre que celle expressément validée par CCCC.<br>Cette dernière, n'est en aucun cas responsable de dysfonctionnements imputables à des logiciels de tiers que ceux-ci soient ou non incorporés dans le site ou fournis avec celui-ci. En aucun cas, CCCC ne sera responsable de tout type de dommage prévisible ou imprévisible (incluant la perte de profits ou d’opportunité...) découlant de l’utilisation ou de l’impossibilité totale ou partielle d’utiliser le site. Le Client déclare connaître les caractéristiques et les limites de l’internet, en particulier ses performances techniques, les temps de réponse pour consulter, interroger ou transférer des données et les risques liés à la sécurité des communications.
      <br>
      <br>
      <strong>2.5 Modes de paiement</strong>
      <br>CCCC accepte les virements interbancaires, les règlements en espèces en euros, les chèques tirés exclusivement sur une agence bancaire domiciliée en France.<br>L’encaissement d’un moyen de paiement par CCCC conditionne le traitement d’une réservation.<br>Lors de la réservation internet par carte bancaire, le client devra imprimer l'email de confirmation de la réservation ou bien le présenter sur son smartphone. Les passagers devront se présenter en bout de jetée 10 minutes avant le départ, une pièce d’identité pourra leur être demandée. De façon exceptionnelle, tout achat peut se faire à bord du navire, en départ immédiat sous réserve de places disponibles.<br>Pour les prestations « sur devis », le montant définitif de la commande devra être communiqué 10 jours avant la prestation et donnera lieu à une facture de solde, déduction faite des arrhes versés à la commande. Le paiement du solde s’effectuera à réception de la facture de solde dans un délai maximum de 5 jours ouvrés. Pour les prestations supplémentaires fournies le jour de la prestation, elles seront à régler en intégralité le jour de la prestation et directement sur place.
      <br>
      <br>
      <strong>2.6 Défaut de règlement</strong>
      <br>
      <br><i>2.6.a Prestation réalisée sur la base d'un devis</i>
      <br>En cas d’accord sur devis d’un paiement et d’un règlement de solde de tout compte après la réalisation de la prestation, tout retard de paiement entraînera de plein droit la facturation de pénalités de retard. L’Article L.441.6 du Code de Commerce précise que, les pénalités de retard sont exigibles sans qu’un rappel soit nécessaire. Ces pénalités de retard seront appliquées de la date d’exigibilité du principal à celle du paiement effectif et total. Conformément à Loi de Modernisation de l’Economie (LME), entrée en vigueur le 1erjanvier 2009, le taux de pénalités de retard appliqué est de 3% l’an. Toute contestation partielle de la facturation ne pourra en aucun cas justifier un défaut de règlement total de la prestation objet des présentes. Le seul défaut de paiement qui sera admis ne devra porter que sur le montant de la contestation, sous réserve que celle-ci soit objectivement fondée et notifiée aux Sociétés Prestataires par lettre recommandée avec accusé de réception.
      <br>
      <br><i>2.6.b Réservation non confirmée par un paiement</i>
      <br>A la suite d’un devis confirmé par le client par un versement d’arrhes, si le paiement du solde n’est pas réalisé dans le respect des conditions contractuelles convenues, la totalité des arrhes versées est conservée par CCCC.
      <br>
      <br>
      <strong>3. Annulation partielle ou totale</strong>
      <br>
      <br>
      <strong>3.1 Annulation partielle ou totale de la réservation par le client</strong>
      <br>Le Client peut procéder à l’annulation partielle ou totale de sa réservation dans les conditions ci-après.<br>Toute annulation doit être notifiée à CCCC par courrier recommandé avec accusé de réception adressé à CCCC, 49 allée du Rivage 33 950 Lège-Cap Ferret. La date de la réception de l’annulation fera foi pour l’application des frais d’annulation prévus ci-après.
    </p>
    <ul>
      <li>Pour une annulation de plus de 15 jours calendaires avant la prestation, aucun frais d’annulation n’est dû par le client.</li>
      <li>Pour une annulation comprise entre J-15 et J-5 jours calendaires avant la date prévue, une pénalité représentant 40% (quarante pour cent) du prix TTC de la prestation est due par le Client, quel que soit le montant de la prestation. </li>
      <li>Pour une annulation intervenue moins de 5 (cinq) jours calendaires avant la date de la prestation ou non présentation du Client le jour J : une pénalité représentant 100% (cent pour cent) du prix TTC de la prestation est due par le Client ; CCCC se réservant, le cas échéant, la faculté de commercialiser à nouveau les places inutilisées. Les pénalités restent en tout état de cause acquises à CCCC.</li>
    </ul>
    <p>
      <br>Le personnel du bateau n’est pas habilité à effectuer une opération de remboursement.
      <br>
      <br>
      <strong>3.2 Annulations ou modifications des prestations par CCCC</strong>
      <br>CCCC se réserve la possibilité d’annuler ou de reporter à tout moment la prestation en cas de force majeure (incendie, explosion, catastrophe naturelle, contraintes administratives etc...) ou tout autre fait indépendant de leur volonté.<br>Les balades maritimes étant soumises aux règles de la navigation, CCCC se réserve le droit d’apprécier le caractère praticable des voies navigables. En conséquence, elles pourront être annulées ou modifiées à tout moment à compter de la réservation y compris au moment de l’appareillage ou durant la navigation, en application des règles susvisées, ou en cas d’intempéries de nature à mettre en péril la sécurité des personnes transportées.<br>En cas d'annulation de la totalité des prestations, CCCC s'engage avec l'accord préalable du client, soit à imputer les sommes versées à titre d'arrhes sur une réservation ultérieure, soit à rembourser purement et simplement les dites sommes. Toutefois, CCCC ne sera pas tenue responsable de tout dommage direct ou indirect qui résulterait de cette annulation.
      <br>
      <br>
      <strong>4 Règles et recommandations de fonctionnement</strong>
      <br>
      <br>
      <strong>4.1 Respect des horaires</strong>
      <br>Le client, ou le groupe au complet doivent se présenter au lieu d’embarquement au plus tard 10 minutes avant l’heure prévue de départ. En cas d’arrivée après le départ du bateau, ils ne pourront prétendre à aucun remboursement.<br>La responsabilité de CCC ne saurait être engagée en cas de modification d’horaires, d’itinéraire, de retard, de déroutement ou de suppression de départ pour cause de force majeure, fortuite, d’avarie, conditions atmosphériques défavorables, de grèves ou autre.
      <br>
      <br>
      <strong>4.2 Respect du programme</strong>
      Le Capitaine peut modifier ou supprimer le trajet ou les escales prévues au programme en cas de nécessité dont il est seul juge, et ce pour raison de sécurité, pour offrir ou prêter assistance en vue de sauver des vies humaines ou des biens. Les horaires de départ ou d’arrivée, l’itinéraire des navires et les escales prévues pourront de ce fait être modifiés sans préavis et sans que les passagers puissent se prévaloir d’un quelconque préjudice en résultant pour en obtenir dédommagement.<br>En cas d’un départ retardé, imputable au client, CCC se réserve le droit de réduire la prestation d’un temps équivalent au retard pris.
      <br>
      <br>
      <strong>4.3 Sécurité et comportement</strong>
      <br>Pour des raisons de sécurité, CCCC invite chaque passager à tenir compte du comportement particulier d’un navire à la mer qui peut présenter des mouvements de plate-forme. Il est recommandé de limiter les déplacements à bord du bateau ou de se faire assister par l’équipage si nécessaire.<br>Les enfants mineurs voyagent sous la surveillance et la responsabilité de leurs parents ou de l’adulte accompagnant.<br>Il est interdit aux passagers de transporter des matières dangereuses dans leurs bagages. Sont considérées comme matières dangereuses : les produits hydrocarbures, gaz, produits inflammables, corrosifs ou explosifs tels que référencés par le code IMDG de la réglementation maritime.<br>CCCC a liberté de prendre toutes mesures pour empêcher le transport de marchandises dangereuses.<br>Tout contrevenant pourra se voir refuser l’accès ou être expulsé, même en cours de prestation, sans possibilité d’être remboursé du prix payé, CCCC se réservant en outre le droit de donner toutes les suites judiciaires qu’il jugera utile. Le nombre maximum de passagers accepté est indiqué à bord des bateaux. Pour des raisons de sécurité le nombre de places fixées sera strictement respecté.<br>Il est rappelé aux passagers que la consommation d’alcool ou de drogue est interdite à bord sous peine de poursuite.<br>Dans le cadre des prestations convenues, la consommation des alcools vendus à bord par CCCC est autorisée.
      <br>
      <br>
      <strong>4.4 Obligation des passagers</strong>
      <br>A compter du moment où il est invité à embarquer, chaque passager devra se conformer immédiatement et strictement aux instructions données par le personnel du bateau dans l’intérêt de la sécurité de la navigation et de l’ordre à bord.<br>Les passagers à bord du navire sont soumis à l’autorité du Capitaine et de ses préposés. A ce titre, ils doivent respecter l’ensemble des règlements et instructions affichés ou donnés lors des opérations d’embarquement, de débarquement et pendant toute la durée de la prestation.<br>Le personnel de CCCC pourra refuser l’accès au navire à toute personne dont le comportement pourrait nuire à l’ordre public ou mettre en danger la sécurité des autres passagers ou du navire.<br>En outre, chaque passager sera tenu de veiller à sa propre sécurité et à celle des personnes dont il a la garde, notamment des enfants de moins de 18 ans.<br>Une tenue correcte est exigée et un comportement respectueux, tant des clients que des prestataires extérieurs (artistes, animateur…) et membres du personnel, est de rigueur.<br>CCCC décline toute responsabilité en cas de vol ou de dégâts causés aux vêtements, bagages à main ou matériel (tel qu’appareil photo) des passagers. Il ne sera admis sur le bateau aucun animal ni aucun matériel pouvant se révéler dangereux pour les passagers ou le personnel.<br>Il est interdit à toute personne étrangère au service de s’introduire sans permission spéciale dans le compartiment moteur, ainsi que dans le poste de pilotage et la cuisine.
      <br>
      <br>
      <strong>4.5 Animaux</strong>
      <br>Sur décisions du capitaine les animaux peuvent être admis à bord de nos navires. Ces animaux voyagent gratuitement sous la seule responsabilité de leurs propriétaires. Ils seraient acceptés tenus en laisse, selon la réglementation en vigueur ou dans un panier et sans aucune garantie de perte, maladie ou mortalité.<br>Les animaux ne doivent pas occuper une place assise.
      <br>
      <br>
      <strong>5. Assurance / Responsabilité</strong>
      <br>CCCC déclare être dûment assuré auprès d’une compagnie d’assurance notoirement solvable pour sa responsabilité civile d’exploitation.<br>CCCC se réserve le droit de facturer au Client tout dégât de matériels causés pendant la prestation par lui-même ou un membre de son groupe.<br>Elle décline toute responsabilité sur les conséquences de l’inobservation par les passagers des règles susvisées et des règlements de police, sans préjudice des dommages et intérêts qu’elles pourront demander en raison du préjudice qu’elles auront subi du fait de cette inobservation.<br>CCCC ne pourra être tenue responsable des dommages directs ou indirects survenus aux passagers lors de la prestation lorsque ceux-ci auront été occasionnés par l’une des causes suivantes : crue subite, orage, collision avec un autre navire responsable de l’accident, même partiellement, et tout autre évènement imprévisible
      <br>
      <br>
      <strong>6. Informatique et Libertés / Gestion des données personnelles</strong>
      <br>CCCC respecte la législation en vigueur en matière de protection de la vie privée.<br>Notamment, son site internet est déclaré auprès de la CNIL conformément à la loi « Informatique et Libertés ». Dans le cadre de la relation commerciale, CCCC est amené à solliciter le Client par mail pour, par exemple, lui faire parvenir la confirmation de sa commande.<br>A l’occasion de l’utilisation du site www.capferretcruise.com, peuvent être recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site www.capferretcruise.com, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.<br>En tout état de cause CCCC ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site www.capferretcruise.com. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur du site www.capferretcruise.com l’obligation ou non de fournir ces informations.<br>Le Client dispose d’un droit d’accès, de rectification et de suppression des données qui le concernent (Loi n°2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel et modifiant la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés et aussi par la Directive Européenne du 24 octobre 1995). Pour cela, il suffit de contacter par courrier CCCC à l’adresse suivante : 49 Allée du rivage 33950 Lège-Cap-Ferret. Une copie de la carte d’identité devra être jointe au courrier. Les informations indispensables au traitement de la commande sont signalées sur les sites par des astérisques.
      <br>
      <br>
      <strong>7. Propriété intellectuelle et confidentialité</strong>
      <br>CCCC est titulaire de tous les droits de propriété intellectuelle relatifs à la société et marque : "Cap-Ferret Cruise Confort Company" et à son site internet "www.capferretcruise.com" qui lui appartient ou dont elle détient les droits. L’accès aux sites digitaux de CCCC ne confère aucun droit aux utilisateurs.<br>CCCC et le Client s’engagent réciproquement à garder la plus stricte confidentialité sur l’ensemble des documents et informations qui seraient portés à leur connaissance dans le cadre de l’exécution des prestations.<br>Les éléments accessibles sur les bateaux et sites, notamment sous forme de textes, photographies, images, icônes, sons, sont également protégés par des droits de propriété intellectuelle et industrielle et autres droits privatifs.<br>Sauf dispositions explicites signalées dans le présent document, le Client ne peut en aucun cas, reproduire, représenter, modifier, transmettre, publier, adapter, sur quelque support que ce soit, par quelque moyen que ce soit, ou exploiter de quelque manière que ce soit, tout ou partie des sites internet, propriétés de CCCC, sans autorisation préalable. L’exploitation non préalablement autorisée par CCCC à quelque titre que ce soit, de tout ou partie des informations pourra faire l’objet de toute action appropriée, notamment d’une action en contrefaçon comme sanctionnée par les articles L.335-2 et suivants du Code de la Propriété Intellectuelle.
      <br>
      <br>
      <strong>8. Réclamation et juridiction compétente</strong>
      <br>Toute réclamation relative à une prestation devra être adressée par lettre recommandée avec accusé de réception au plus tard dans les 8 jours suivant ladite prestation à&nbsp;:<br>CAP-FERRET CRUISE CONFORT COMPANY<br>49, allée du Rivage<br>33950 LEGE-CAP-FERRET<br>En cas de litige ou de contestation, seules les lois françaises sont applicables et les juridictions françaises seront seules compétentes.<br>L’illégalité ou la nullité d’une clause d’un paragraphe ou d’une stipulation quelconque de ce règlement n’affectera ou n’invalidera aucun autre.<br>Toute action intentée contre la compagnie par les passagers ou leurs représentants ne pourra être jugée que devant le Tribunal de Commerce de BORDEAUX.
      <br>
      <br>
    </p>
  </div>
</div>
