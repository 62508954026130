<nav class="navbar navbar-inverse navbar-fixed-top" role="navigation">
  <div id="header" class="container-fluid">
    <div id="topbar" class="topbar">
      <div class="col-xs-6 col-no-rp">
        <div class="visible-lg">
          <a target="_blank" rel="noopener" id="facebook" href="https://www.facebook.com/CapFerretCruiseConfortCompany"><i class="fa fa-facebook-f"></i></a>
          <a target="_blank" rel="noopener" id="instagram" href="https://www.instagram.com/capferretcruise"><i class="fa fa-instagram"></i></a>
          <a href="/plan-d-acces" title="Plan d'accès à l'embarcadère" id="accesportembarquement" class="text-uppercase">{{'PlanEmbarcadere' | translate}}</a>
        </div>
        <a target="_blank" rel="noopener" id="facebook-xs" href="https://www.facebook.com/CapFerretCruiseConfortCompany" aria-label="Retrouvez nous sur Facebook"><i class="fa fa-facebook-f hidden-lg"></i></a>
        <a target="_blank" rel="noopener" id="instagram-xs" href="https://www.instagram.com/capferretcruise" aria-label="Retrouvez nous sur Instagram"><i class="fa fa-instagram hidden-lg"></i></a>
        <div class="hidden-lg">
          <a href="/">
            <img class="img-sm-custom" src="../../assets/img/favicon.png" alt="Retour à l'accueil">
          </a>
          <a href="/plan-d-acces" title="Plan" class="hidden-lg text-uppercase">{{'PlanAcces' | translate}}</a>
        </div>
      </div>
      <div id="topbar-right-part" class="col-xs-6 text-right col-no-lp">

        <div id="languages_block_top">
          <ul class="list-inline">
            <li>
              <button (click)="switchLang('en')"  title="English (English)" [class]="isActive('en') ? 'active' : ''">en</button>
            </li>
            <li>
              <button (click)="switchLang('fr')" title="Français (French)" [class]="isActive('fr') ? 'active' : ''">fr</button>
            </li>
          </ul>
        </div>
        <nav role="navigation" class="hidden-lg">
          <div id="menuToggle">
            <!--
            A fake / hidden checkbox is used as click reciever,
            so you can use the :checked selector on it.
            -->
            <input [(ngModel)]="burger" type="checkbox" (blur)="burger=false" aria-label="Menu burger"/>

            <!--
            Some spans to act as a hamburger.

            They are acting like a real hamburger,
            not that McDonalds stuff.
            -->
            <span></span>
            <span></span>
            <span></span>

            <!--
            Too bad the menu has to be inside of the button
            but hey, it's pure CSS magic.
            -->
            <ul id="menu">
              <li [routerLinkActive]="['active']"><a [routerLink]="['home']" (click)="burger=false">{{'Accueil' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['balades']" (click)="burger=false">{{'Balades' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['options']" (click)="burger=false">{{'NosOptions' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['evenements']" (click)="burger=false">{{'Evenements' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['contact']" (click)="burger=false">{{'Contact' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['reserver']" (click)="burger=false">{{'Reserver' | translate}}</a></li>
              <li [routerLinkActive]="['active']"><a [routerLink]="['boat']" (click)="burger=false">{{'Histoire' | translate}}</a></li>
            </ul>
          </div>
        </nav>

      </div>

    </div>

    <div id="header_right" class="navbar-collapse visible-lg">
      <div class="container">
        <div id="" class="row"></div>
        <div class="navbar-header">
          <a class="navbar-img-custom" href="/">
            <img loading="lazy" width="175" src="../../assets/img/accueil.png" alt="Retour à l'accueil"></a>
        </div>
        <ul class="nav navbar-nav">
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['home']">{{'Accueil' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['balades']">{{'Balades' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['options']">{{'NosOptions' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['evenements']">{{'Evenements' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['contact']">{{'Contact' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet-reserver" [routerLink]="['reserver']">{{'Reserver' | translate}}</a></li>
          <li [routerLinkActive]="['active']"><a class="onglet" [routerLink]="['boat']">{{'Histoire' | translate}}</a></li>
        </ul>
      </div>

    </div>
  </div>
</nav>
