<div class="container encart">
    <h1 class="h1 titre-encart">
      {{"AccesEmbarcadere" | translate}}
    </h1>

  <div class="row">
    <div class="col-xs-12">
      <p>
        {{'EmbarcadereInformation1' | translate}}
        <strong>{{'EmbarcadereInformation2' | translate}}</strong></p>
    </div>
  </div>

  <div id="listing-harbours" style="margin-top: 0;">
    <div class="row mye-1">
      <div class="col-xs-12">
        <div id="ponton_grand_piquey" class="pye-3 pxe-2 harbour-item bg-home-grey container-fluid">
          <div id="ponton" class="anchorHarbour"></div>
          <div class="col-md-5">
            <h2>
              Ponton Grand Piquey - Rue Louis Gaume <br/>
              Commune de Lège-Cap-Ferret
            </h2>
            <div class="">
              <a rel="noopener" href="https://www.google.com/maps/dir//13-17+Rue+Louis+Gaume,+33950+L%C3%A8ge-Cap-Ferret/@44.7140602,-1.2122046,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd54a07567370425:0xf3f35b43b2b70df5!2m2!1d-1.211802!2d44.7142312!3e0" target="_blank" class="btn btn-brc-red">{{'Itinéraire' | translate}}</a>
            </div>
            <div class="harbour-description pye-3">
              <p><strong [innerHTML]="'Adresse' | translate"></strong></p>
              <p><strong>13-17 rue Louis Gaume,&nbsp;</strong><strong>33950 Lège-Cap-Ferret</strong></p>
              <p><strong [innerHTML]="'Reperes' | translate"></strong></p>
              <p>{{'ReperesDescription' | translate}}</p>
              <p><strong [innerHTML]="'Acces' | translate"></strong></p>
              <p>{{'AccesDescription' | translate}}<br><span style="line-height: 1.5em;"><span>{{'AccesDescription2' | translate}}&nbsp;<a rel="noopener" target="_blank" href="https://www.bassin-arcachon-info.com/bus-bordeaux-cap-ferret/">{{'AccesDescriptionLien' | translate}}</a>. </span></span></p>
            </div>
          </div>
          <div class="col-md-7">
            <div class="harbour-map-container">
              <app-map></app-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
