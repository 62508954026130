<div  class="banner"></div>
<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"promo.titre" | translate}}
  </h1>

  <form [formGroup]="promoForm" id="signupForm" (submit)="onSubmit()" *ngIf="true">

    <div class="row">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{'Nom' | translate}}</mat-label>
            <input class="input-contact" [formControl]="labelControl" matInput placeholder="Etienne Martin" required>
            <mat-error *ngIf="labelControl.invalid">{{getMessage(labelControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reservation.adultes' | translate }}</mat-label>
            <input
              matInput
              inputNumber
              class="input-option-detail"
              [formControl]="adulteControl"
              (ngModelChange)="autoCalculate()"
              [min]="0" [max]="12" required>
            <mat-error *ngIf="adulteControl.invalid" class="errorMessage">{{getMessage(adulteControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{'E-mail' | translate}}</mat-label>
      <input class="input-contact" [formControl]="emailControl" matInput placeholder="e.martin@mail.fr" required>
      <mat-error *ngIf="emailControl.invalid" [innerHTML]="getMessage(emailControl) | translate"></mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{'Telephone' | translate}}</mat-label>
      <mat-icon matSuffix>phone</mat-icon>
      <input class="input-contact" [formControl]="telephoneControl" matInput placeholder="ex: 0123456789">
    </mat-form-field>
  </form>
</div>
