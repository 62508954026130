import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  GroupElement,
  ListeElementsCheckbox,
  OptionsSupplementairesReservation,
  PrestationComplete,
  Reservation, TitreOption, TitrePrestation
} from "../model/element.model";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {BackendApiService} from "../services/backend-api.service";
import {map} from "rxjs/operators";
import {DatePipe} from "@angular/common";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {forkJoin, Observable} from "rxjs";
import {GcpService} from "../services/gcp.service";

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  prestations$: Observable<[void, void]>
  options$: Observable<ListeElementsCheckbox[]>;
  options: ListeElementsCheckbox[];

  today = new Date();
  minDate = new Date(2024, 2, 28);
  selection: PrestationComplete = null;
  reservationDetail: Reservation;

  submitted = false;
  disabled = false;
  subTotalBalade = 0;
  subTotalAdultes = 0;
  subTotalEnfants = 0;
  subTotalHeures = 0;

  subTotalOptions = 0;

  total = 0;

  reservationForm: UntypedFormGroup;

  groupChoices: GroupElement[] = [];

  balades: PrestationComplete[] = [];
  events: PrestationComplete[] = [];

  datesChoix: Date[] = [];
  erreurDatesMessage: boolean;
  messageOffice: boolean;

  choixControl = new UntypedFormControl('', [Validators.required]);
  adulteControl = new UntypedFormControl('', [Validators.required, Validators.min(0), Validators.max(12)]);
  enfantControl = new UntypedFormControl('', [Validators.min(0), Validators.max(11)]);
  enfant3ansControl = new UntypedFormControl('', [Validators.min(0), Validators.max(11)]);
  heuresSuppControl = new UntypedFormControl('');
  optionsControl: UntypedFormControl[][] = [];
  citadineControl = new UntypedFormControl('', [Validators.min(0)]);
  panierControl = new UntypedFormControl('', [Validators.min(0)]);
  localeControl = new UntypedFormControl('', [Validators.min(0)]);
  petiteLocaleControl = new UntypedFormControl('', [Validators.min(0)]);
  rougeMachorreControl = new UntypedFormControl('', [Validators.min(0)]);
  rougePironControl = new UntypedFormControl('', [Validators.min(0)]);
  blancPironControl = new UntypedFormControl('', [Validators.min(0)]);
  blancFontenilleControl = new UntypedFormControl('', [Validators.min(0)]);
  roseGoujonneControl = new UntypedFormControl('', [Validators.min(0)]);
  roseNavicelleControl = new UntypedFormControl('', [Validators.min(0)]);
  champagneControl = new UntypedFormControl('', [Validators.min(0)]);
  cocaControl = new UntypedFormControl('', [Validators.min(0)]);
  cocaZeroControl = new UntypedFormControl('', [Validators.min(0)]);
  jusOrangeControl = new UntypedFormControl('', [Validators.min(0)]);
  jusPommeControl = new UntypedFormControl('', [Validators.min(0)]);
  biereControl = new UntypedFormControl('', [Validators.min(0)]);
  dateControl = new UntypedFormControl('', [Validators.required]);
  matDateControl = new UntypedFormControl('');

  optionsSupplementairesReservations: OptionsSupplementairesReservation[] = [];

  idSelection = '';

  totalForPayment = 0;

  // A mettre à jour pour passer en paiement en ligne ou non
  paymentActived = false;
  ferme: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private backEndService: BackendApiService,
              private datePipe: DatePipe,
              private ngxLoader: NgxUiLoaderService,
              private gcpService: GcpService) {
    this.route.params.subscribe( params => this.idSelection = params.id );
    this.ferme = this.gcpService.isFerme();
    // Paramètre pour permettre d'activer l'url sans paiement
    //this.paymentActived = !this.route.snapshot.queryParams.camille;
  }

  minTwo(): ValidatorFn {
    return () => {
      if(this.adulteControl.value + this.enfantControl.value < 2) {
        this.adulteControl.setErrors({notEnoughTwo: true});
        this.enfantControl.setErrors({notEnoughTwo: true});
        return {notEnoughTwo: true};
      } else {
        if(this.adulteControl.errors && this.adulteControl.errors.notEnoughTwo) {
          this.adulteControl.setErrors(null);
        }
        if(this.enfantControl.errors && this.enfantControl.errors.notEnoughTwo) {
          this.enfantControl.setErrors(null);
        }
        return null;
      }
    }
  }

  minSix(): ValidatorFn {
    return () => {
      if(this.adulteControl.value + this.enfantControl.value < 6) {
        this.adulteControl.setErrors({notEnoughSix: true});
        this.enfantControl.setErrors({notEnoughSix: true});
        return {notEnoughSix: true};
      } else {
        if(this.adulteControl.errors && this.adulteControl.errors.notEnoughSix) {
          this.adulteControl.setErrors(null);
        }
        if(this.enfantControl.errors && this.enfantControl.errors.notEnoughSix) {
          this.enfantControl.setErrors(null);
        }
        return null;
      }
    }
  }

  maxTwelve(): ValidatorFn {
    return () => {
      if (this.adulteControl.value + this.enfantControl.value + this.enfant3ansControl.value > 12) {
        this.adulteControl.setErrors({tooMany: true});
        this.enfantControl.setErrors({tooMany: true});
        this.enfant3ansControl.setErrors({tooMany: true});
        return {tooMany: true};
      } else {
        if(this.adulteControl.errors && this.adulteControl.errors.tooMany) {
          this.adulteControl.setErrors(null);
        }
        if(this.enfantControl.errors && this.enfantControl.errors.tooMany) {
          this.enfantControl.setErrors(null);
        }
        if(this.enfant3ansControl.errors && this.enfant3ansControl.errors.tooMany) {
          this.enfant3ansControl.setErrors(null);
        }
        return null;
      }
    }
  }

  ngOnInit(): void {

    this.reservationForm = this.formBuilder.group( {
      choix: this.choixControl,
      adulte: this.adulteControl,
      enfant: this.enfantControl,
      enfant3ans: this.enfant3ansControl,
      heuresSupplementaires: this.heuresSuppControl,
      date: this.dateControl,
      matDate: this.matDateControl
    }, {
      validators: [this.maxTwelve()]
    });

    this.choixControl.setValue(this.idSelection);

    this.enfantControl.setValue(0);
    this.enfant3ansControl.setValue(0);

    this.groupChoices.push(
      { name: "balades", choices: [] },
      { name: "events", choices: [] }
    );

    const balades$ = this.backEndService.getBalades().pipe(
      map(
        (res: {balades: PrestationComplete[]}) => res.balades.filter(
          balade => !!balade.titre
        )
      ),
      map((balades: PrestationComplete[]) => {
        this.groupChoices[0].choices = balades;
        this.razTotal();
        this.recalculatingPrice();
        this.manageValidators();
      })
    );

    const events$ = this.backEndService.getEvents().pipe(
      map(
        (res: {events: PrestationComplete[]}) => res.events.filter(
          event => !!event.titre
        )
      ),
      map((events: PrestationComplete[]) => {
      this.groupChoices[1].choices = events;
      this.razTotal();
      this.recalculatingPrice();
      this.manageValidators();
    }));

    this.prestations$ = forkJoin([balades$, events$]);

    this.options$ = this.backEndService.getOptions().pipe(map((res:any) => {
      const options: ListeElementsCheckbox[] = [];
      for (let optionAssiette of res.options[0].elements) {
        optionAssiette.checked = false;
      }
      for (let optionVin of res.options[1].elements) {
        optionVin.checked = false;
      }
      for (let optionSansAlcool of res.options[2].elements) {
        optionSansAlcool.checked = false;
      }
      const degustations = res.options[0].elements;
      const vins = res.options[1].elements.filter(vin => vin.prix > 0);
      const softs = res.options[2].elements;
      options.push(
        {titre: "Assiettes", elements:degustations, informationsComplementaires: "Inclus", description: "DescriptionAssiettes"},
        {titre: "Vins", elements: vins, description: "DescriptionVins"},
        {titre: "Softs", elements: softs, description: "DescriptionSofts"}
      );
      this.options = options;
      return options;

    }));

    this.optionsControl[0] = [];
    this.optionsControl[0][0] = this.citadineControl;
    this.optionsControl[0][1] = this.panierControl;
    this.optionsControl[0][2] = this.localeControl;
    this.optionsControl[0][3] = this.petiteLocaleControl;
    this.optionsControl[1] = [];
    this.optionsControl[1][0] = this.rougeMachorreControl;
    this.optionsControl[1][1] = this.rougePironControl;
    this.optionsControl[1][2] = this.blancPironControl;
    this.optionsControl[1][3] = this.blancFontenilleControl;
    this.optionsControl[1][4] = this.roseGoujonneControl;
    this.optionsControl[1][5] = this.roseNavicelleControl;
    this.optionsControl[1][6] = this.champagneControl;
    this.optionsControl[2] = [];
    this.optionsControl[2][0] = this.cocaControl;
    this.optionsControl[2][1] = this.cocaZeroControl;
    this.optionsControl[2][2] = this.jusOrangeControl;
    this.optionsControl[2][3] = this.jusPommeControl;
    this.optionsControl[2][4] = this.biereControl;
  }

  getSelection(): PrestationComplete {
    if (this.choixControl.value) {
      for( const group of this.groupChoices) {
        for (const choice of group.choices) {
          if(this.choixControl.value === choice.titre) {
            return choice;
          }
        }
      }
    }
    return null;
  }

  manageValidators() {
    if(this.selection) {
      if (this.selection.titre === 'TitreInevitable') {
        this.reservationForm.clearValidators();
        this.reservationForm.setValidators([this.maxTwelve(), this.minTwo()]);
      } else if (this.selection.titre === 'TitreExquise') {
        this.reservationForm.clearValidators();
        this.reservationForm.setValidators([this.maxTwelve(), this.minSix()]);
      } else {
        this.reservationForm.clearValidators();
        this.reservationForm.setValidators(this.maxTwelve());
      }
      if (this.selection.titre === 'TitreLibre') {
        this.heuresSuppControl.setValidators(Validators.required);
        this.heuresSuppControl.setValidators(Validators.min(0));
        this.heuresSuppControl.setValidators(Validators.max(6));
        this.heuresSuppControl.setValue(0);
      } else {
        this.heuresSuppControl.setValue(null);
        this.heuresSuppControl.clearValidators();
        this.heuresSuppControl.updateValueAndValidity();
      }
      if (this.selection.reservation) {
        this.dateControl.setValidators([Validators.required]);
        this.matDateControl.clearValidators();
      } else {
        this.dateControl.clearValidators();
        this.matDateControl.setValidators([Validators.required]);
      }
    }
    this.adulteControl.setValidators([Validators.required, Validators.min(0), Validators.max(12)]);
    this.enfantControl.setValidators([Validators.min(0), Validators.max(11)]);
    this.adulteControl.updateValueAndValidity();
    this.enfantControl.updateValueAndValidity();
    this.dateControl.updateValueAndValidity();
    this.matDateControl.updateValueAndValidity();
    this.reservationForm.updateValueAndValidity();
  }

  recalculatingPrice() {
    this.selection = this.getSelection();
    this.recalculatingBaladPrice();
    this.recalculatingAdultPrice();
    this.recalculatingEnfantPrice();
    this.recalculatingHeuresPrice();
    this.recalculatingTotalPrice();
  }

  recalculatingAdultPrice(): void {
    this.subTotalAdultes = this.selection?.prixAdulte ? this.selection.prixAdulte * this.adulteControl.value : 0;
    this.reservationForm.updateValueAndValidity();
  }

  recalculatingEnfantPrice(): void {
    this.subTotalEnfants = this.selection?.prixEnfant ? this.selection.prixEnfant * this.enfantControl.value : 0;
    this.reservationForm.updateValueAndValidity();
  }

  recalculatingEnfant3ansPrice(): void {
    this.reservationForm.updateValueAndValidity();
  }

  recalculatingHeuresPrice(): void {
    if(this.heuresSuppControl.valid) {
      this.subTotalHeures = this.selection?.prixHeureSupp ? this.selection.prixHeureSupp * this.heuresSuppControl.value : 0;
    }
  }

  getLabelPrice(id: string): string {
    let prix = 'inconnu';
    this.priceBaseCalculatedBalade();
    for(let group of this.groupChoices) {
      for (let prestation of group.choices) {
        if (id === prestation.titre) {
          prix = prestation.prix;
        }
      }
    }
    return prix;
  }

  isHeureSupp(): boolean {
    return ("TitreLibre" === this.choixControl.value);
  }

  getErrorMessage(formControl: UntypedFormControl): string {
    if (formControl.hasError('required')) {
      return 'error.required';
    }
    if(formControl.hasError('max') && (formControl.errors.max.max === 6)) {
      return 'error.tropLong';
    }
    if (formControl.hasError('min')) {
      return 'error.notEnough';
    }
    if (formControl.hasError('tooMany')) {
      return 'error.tooMany';
    }
    if (formControl.hasError('notEnoughTwo')) {
      return 'error.notEnoughTwo';
    }
    if (formControl.hasError('notEnoughSix')) {
      return 'error.notEnoughSix';
    }
  }

  priceBaseCalculatedBalade(): void {
      this.recalculatingBaladPrice();
  }

  priceCalculatedAdults(): number | string {
    return this.subTotalAdultes > 0 ? this.subTotalAdultes : " - ";
  }

  priceCalculatedChildren(): number | string {
    return this.subTotalEnfants > 0 ? this.subTotalEnfants : " - ";
  }

  priceCalculatedHours(): number | string {
    return this.subTotalHeures > 0 ? this.subTotalHeures : " - ";
  }

  priceCalculatedSubTotal(): number {
    let subtotal = this.subTotalBalade + this.subTotalAdultes + this.subTotalEnfants + this.subTotalHeures;
    this.total = subtotal + this.subTotalOptions;
    return subtotal;
  }

  priceRecalculatedOptions(options: ListeElementsCheckbox[]): number {
    this.subTotalOptions = 0;
    let i = 0;
    let j = 0;
    for (let option of options) {
      for (let element of option.elements) {
        if(element.checked) this.subTotalOptions = this.subTotalOptions + this.optionsControl[i][j].value * element.prix;
        j++;
      }
      i++;
      j=0;
    }
    this.recalculatingTotalPrice();
    return this.subTotalOptions;
  }

  omitNegativeSign(event) {
    const input = event.key;
    if (input === "-") {
      event.preventDefault()
    }
  }

  optionsValide(): boolean {
    let i = 0;
    let j = 0;
    this.optionsSupplementairesReservations = [];
    for(let option of this.options) {
      for(let element of option.elements) {
        if (element.checked) {
          if(!this.optionsControl[i][j].valid) {
            return false;
          } else {
            if (this.optionsControl[i][j].value) {
              this.optionsSupplementairesReservations.push({
                idOption: element.titre,
                titreOption: TitreOption[element.titre],
                nbOption: this.optionsControl[i][j].value,
                priceOption: element.prix
              })
            }
          }
        }
        j=j+1;
      }
      i=i+1;
      j=0;
    }
    return true;
  }

  razTotal() {
    this.totalForPayment = 0;
  }

  setInitValueCheckbox(checkboxControl: UntypedFormControl, checked: boolean) {
    checked ? checkboxControl.setValue(1) : checkboxControl.setValue(0);
  }

  isDisabled() {
    return (this.disabled || !this.reservationForm.valid);
  }

  recalculatingDate() {
    if(this.matDateControl.value) {
      this.getDateDisponibleFromDate(this.matDateControl.value);
    }
  }

  getDatesDisponibles(event) {
    if(!this.selection.reservation) return;
    this.getDateDisponibleFromDate(event.value);
  }

  private getDateDisponibleFromDate(date) {
    this.erreurDatesMessage = false;
    this.dateControl.reset();
    date.setHours(12);
    // office du tourisme
    // if (date.getDay() !== 2) {
      this.ngxLoader.startLoader('loaderDate');
      // this.messageOffice = false;
      const nbPassager = this.adulteControl.value + this.enfantControl.value + this.enfant3ansControl.value;
      this.backEndService.getDatesDisponibles(date, this.selection.titre, this.heuresSuppControl.value || 0, nbPassager || 0).subscribe(
        res => {
          this.ngxLoader.stopLoader('loaderDate');
          this.datesChoix = res.heuresAProposer.filter(value => ((new Date(value).getTime())-(new Date().getTime())) > 0);
          if(this.datesChoix.length===1) {
            this.dateControl.setValue(this.datesChoix);
          }
        },
        (e) => {
          this.ngxLoader.stopLoader('loaderDate');
          this.datesChoix = [];
          this.erreurDatesMessage = true;
        }
      );
    /* office du tourisme
    } else {
      this.messageOffice = true;
      this.datesChoix = [];
    }*/
  }

  elementRef: ElementRef;

  onSubmit() {
    this.optionsValide();
    const dateFin = this.calculDateFin();
    let reservation: Reservation = {
      idPrestation: this.choixControl.value,
      titrePrestation: TitrePrestation[this.choixControl.value],
      nbAdulte: this.adulteControl.value,
      nbEnfant: this.enfantControl.value,
      nbEnfant3ans: this.enfant3ansControl.value,
      nbHeureSupp: this.heuresSuppControl.value,
      date: this.selection.reservation ? this.datePipe.transform(this.dateControl.value, 'medium') : this.datePipe.transform(this.matDateControl.value, 'longDate'),
      dateDebut: this.selection.reservation ? this.datePipe.transform(this.dateControl.value, 'yyyy-MM-ddTHH:mm:ssZ').toString() : '',
      dateFin,
      optionsSupplementaires: this.optionsSupplementairesReservations
  };
    if(!this.isDisabled()) {
      if(this.selection.reservation) {
        this.backEndService.finalPrice(reservation).subscribe((res: any) => {
          this.totalForPayment = res.total;
          this.reservationDetail = reservation;
        });
      } else {
        this.router.navigateByUrl('/contact', { state : {reservation}})
      }
    }
  }

  private recalculatingBaladPrice(): void {
    this.subTotalBalade = this.selection?.prixBase ? this.selection.prixBase : 0;
  }

  private recalculatingTotalPrice(): void {
    this.total = this.priceCalculatedSubTotal() + this.subTotalOptions;
  }

  private calculDateFin() {
    const dateDebut = new Date(this.dateControl.value);
    switch(TitrePrestation[this.selection.titre]) {
      case TitrePrestation.TitreInevitable:
        return this.datePipe.transform(dateDebut.setHours(dateDebut.getHours() + 2), 'yyyy-MM-ddTHH:mm:ssZ').toString();
      case TitrePrestation.TitreExquise:
        return this.datePipe.transform(dateDebut.setHours(16), 'yyyy-MM-ddTHH:mm:ssZ').toString();
      case TitrePrestation.TitreSunset.toString():
        return this.datePipe.transform(dateDebut.setHours(22), 'yyyy-MM-ddTHH:mm:ssZ').toString();
      case TitrePrestation.TitreLibre.toString():
        return this.datePipe.transform(dateDebut.setHours(dateDebut.getHours() + (2+this.heuresSuppControl.value)), 'yyyy-MM-ddTHH:mm:ssZ').toString();
      default:
        return '';
    }
  }
}
