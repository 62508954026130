
  <div  mat-card-image class="wrapper">
    <owl-carousel-o [options]="customOptions">

      <ng-container *ngFor="let el of carouselElement">
        <ng-template class="slide" carouselSlide>
          <span class="carousel-caption-img">
                <img src='{{ "../../assets/img/" + el.titre + ".jpg" }}' [alt]="el.alt" title="{{ 'titres.' + el.titre | translate}}" class="owl-item">
            </span>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>
