import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {MailReservation, Reservation, TitreOption, TitrePrestation} from "../model/element.model";
import {BackendApiService} from "../services/backend-api.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-reservation-sans-paiements',
  templateUrl: './reservation-sans-paiements.component.html',
  styleUrls: ['./reservation-sans-paiements.component.css']
})
export class ReservationSansPaiementsComponent implements OnInit, OnDestroy {
  @Input() totalAPayer: number;
  @Input() reservationDetail: Reservation;
  @Input() totalPrestation: number;
  @Input() totalOption: number;

  cardName = new UntypedFormControl('', [Validators.required]);
  cardEmail = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]);
  cardTelephone = new UntypedFormControl('', [Validators.required, Validators.pattern(/(^0[0-9]{9})|(^[+]+\d{6,})$/)]);
  succeed: boolean;
  errorMessage = '';
  isError: boolean;
  disabledSubmitButton: boolean;
  formGroup: UntypedFormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private backendService: BackendApiService,
              private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      name: this.cardName,
      email: this.cardEmail,
      telephone: this.cardTelephone
    });
  }

  pay(): void {
    this.disabledSubmitButton = true;
    this.succeed = false;
    this.isError = false;
    if (this.formGroup.valid) {
      this.backendService.confirmCalendarReservation(
        this.reservationDetail,
        {name: this.cardName.value, email: this.cardEmail.value, telephone: this.cardTelephone.value, prix: this.totalAPayer},
        false)
        .subscribe((res) => {
        if (res.error) {
          this.isError = true;
          this.disabledSubmitButton = false;
          this.errorMessage = 'La date choisie semble ne plus être disponible';
        } else {
          const emailReservation: MailReservation = this.initEmailReservation();
          this.backendService.sendMailReservation(emailReservation).pipe(takeUntil(this.destroy$)).subscribe(
            res => {
              this.isError = false;
              this.succeed = true;
            },
            (e) => {
              this.isError = true;
              this.errorMessage = 'Une erreur est survenue avec l\'envoi de mail. Cependant la réservation a bien été prise en compte. Vous pouvez confirmer par téléphone au 06.09.06.45.79';
            }
          );
        }
      },
      () => {
        this.isError = true;
        this.disabledSubmitButton = false;
        this.errorMessage = 'La date choisie semble ne plus être disponible';
      });
    } else {
      this.disabledSubmitButton = false;
      this.isError = true;
      console.log(this.formGroup);
      this.errorMessage = 'Impossible de valider avec ces informations';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initEmailReservation(): MailReservation {
    let listOptions = '';
    for(let option of this.reservationDetail.optionsSupplementaires) {
      if(listOptions !== '') {
        listOptions = listOptions + ', '
      }
      listOptions = listOptions + `${option.nbOption} ${TitreOption[option.idOption]}`;
	  if(TitreOption[option.idOption]==='Bière' && option.nbOption>1) {
		listOptions = listOptions + 's';
	  }
    }
    return {
      ...this.reservationDetail,
      user: this.cardName.value,
      mail: this.cardEmail.value,
      telephone: this.cardTelephone.value,
      titrePrestation: TitrePrestation[this.reservationDetail.idPrestation],
      tarifPrestation: this.totalPrestation.toString(),
      listOption: listOptions,
      tarifOption: this.totalOption.toString(),
      prixTotal: this.totalAPayer.toString(),
    }
  }

  getErrorMessage(formControl: UntypedFormControl): string {
    if (formControl.hasError('required')) {
      return 'error.required';
    } else if (formControl.hasError('pattern')) {
      return 'error.pattern';
    }
  }

  omitNegativeSignAndTrim(event) {
    const input = event.key;
    if (input === "-" || input === ' ') {
      event.preventDefault()
    }
  }
}
