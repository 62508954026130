import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  DatesPossible,
  MailInformation,
  MailReservation,
  Reservation,
  urlPayment,
  UserInformation
} from "../model/element.model";
import {Observable} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  constructor(private http: HttpClient) { }

  rootURL = '/api';

  sendMail(mailInformation: MailInformation) {
    return this.http.post(this.rootURL + '/send/mailInformation', {mailInformation});
  }

  sendMailReservation(mailReservation: MailReservation) {
    return this.http.post(this.rootURL + '/send/mailReservation', {mailReservation});
  }

  getBalades() {
    return this.http.get(this.rootURL + '/balades');
  }

  getEvents() {
    return this.http.get(this.rootURL + '/events');
  }

  getOptions() {
    return this.http.get(this.rootURL + '/options');
  }

  finalPrice(reservation: Reservation) {
    return this.http.post(this.rootURL+'/reservation/finalPrice', {reservation})
  }

  createPayment(userInformation: UserInformation): Observable<urlPayment> {
    return this.http.post<urlPayment>(this.rootURL + '/createPayment', {userInformation})
  }

  getDatesDisponibles(dateSelected: Date, id: string, heuresLibre: number, nbPassager: number): Observable<DatesPossible> {
    return this.http.post<DatesPossible>(this.rootURL + '/find/datesDisponible', { dateSelected, id, heuresLibre, nbPassager })
  }

  confirmCalendarReservation(reservation: Reservation, userInformation: UserInformation, payment: boolean = false): Observable<any> {
    return this.http.post<any>(this.rootURL + '/reservation/confirm', {reservation, userInformation, payment});
  }
}
