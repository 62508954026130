<div class="row justify-content-center">
  <div class="col-lg-5">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{'prestations.' + prestation?.titre | translate}}</mat-card-title>
        <mat-card-subtitle>{{'prestations.' + prestation?.prix | translate}}</mat-card-subtitle>
      </mat-card-header>
      <app-carousel-mat-card [carouselElement]="prestation?.carousel"></app-carousel-mat-card>
      <mat-card-content>
        <p style="white-space: pre-line; font-size: 17px;" [innerHTML]="'prestations.' + prestation?.circuit | translate"></p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button class="btn btn-cccc-color" *ngIf="type === 'balades'">
          <a mat-button href='{{"reserver/" + prestation?.titre +"?from=balades"}}'>{{ 'prestations.Reserver' | translate }}</a>
        </button>
        <button mat-button class="btn btn-cccc-color" *ngIf="type === 'events'">
          <a mat-button href='{{"contact" }}'>{{ 'prestations.NousContacter' | translate }}</a>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div class="col-lg-7">
    <div class="encart-description">
      <mat-list>
        <mat-list-item>
          <div mat-line><strong [innerHTML]="'prestations.DureePrestation' | translate"></strong></div>
          <div mat-line [innerHTML]="'prestations.' + prestation?.duree | translate"></div>
        </mat-list-item>
        <mat-list-item *ngIf="prestation?.condition">
          <div mat-line><strong [innerHTML]="'prestations.ConditionPrestation' | translate"></strong></div>
          <div mat-line><p [innerHTML]="'prestations.' + prestation?.condition | translate"></p></div>
        </mat-list-item>
        <mat-list-item *ngIf="prestation?.nbPlace">
          <div mat-line><strong [innerHTML]="'prestations.NbPlacePrestation' | translate"></strong></div>
          <div mat-line [innerHTML]="'prestations.' + prestation?.nbPlace | translate"></div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
