import {Component, EventEmitter, Output} from '@angular/core';
import {langUtils} from "../utils/langUtils";

@Component({
  selector: 'app-navigation-component',
  templateUrl: './navigation-component.component.html',
  styleUrls:['./navigation-component.component.css']
})
export class NavigationComponentComponent {
  @Output() switchLangEvent = new EventEmitter();

  lang = langUtils.getDefaultLanguage();
  burger: boolean;

  constructor() { }

  switchLang(lang: string) {
    if (this.lang !== lang) {
      window.localStorage.setItem('lang', lang);
      this.lang=lang;
      this.switchLangEvent.emit(lang);
    }
  }

  isActive(lang: string) {
    return (lang === this.lang);
  }
}
