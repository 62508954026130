import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "@kolkov/ngx-gallery";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: '../../assets/img/boat/boat0-small.JPG',
        medium: '../../assets/img/boat/boat0-medium.JPG',
        big: '../../assets/img/boat/boat0.JPG'
      },
	  {
        small: '../../assets/img/boat/boat14-small.JPG',
        medium: '../../assets/img/boat/boat14-medium.JPG',
        big: '../../assets/img/boat/boat14.JPG'
      },
	  {
        small: '../../assets/img/boat/boat12-small.JPG',
        medium: '../../assets/img/boat/boat12-medium.JPG',
        big: '../../assets/img/boat/boat12.JPG'
      },
      {
        small: '../../assets/img/boat/boat1-small.JPG',
        medium: '../../assets/img/boat/boat1-medium.JPG',
        big: '../../assets/img/boat/boat1.JPG'
      },
      {
        small: '../../assets/img/boat/boat11-small.JPG',
        medium: '../../assets/img/boat/boat11-medium.JPG',
        big: '../../assets/img/boat/boat11.JPG'
      },
      {
        small: '../../assets/img/boat/boat2-small.JPG',
        medium: '../../assets/img/boat/boat2-medium.JPG',
        big: '../../assets/img/boat/boat2.JPG'
      },
      {
        small: '../../assets/img/boat/boat3-small.JPG',
        medium: '../../assets/img/boat/boat3-medium.JPG',
        big: '../../assets/img/boat/boat3.JPG'
      },
      {
        small: '../../assets/img/boat/boat4-small.JPG',
        medium: '../../assets/img/boat/boat4-medium.JPG',
        big: '../../assets/img/boat/boat4.JPG'
      },
      {
        small: '../../assets/img/boat/boat5-small.JPG',
        medium: '../../assets/img/boat/boat5-medium.JPG',
        big: '../../assets/img/boat/boat5.JPG'
      },
      {
        small: '../../assets/img/boat/boat6-small.JPG',
        medium: '../../assets/img/boat/boat6-medium.JPG',
        big: '../../assets/img/boat/boat6.JPG'
      },
      {
        small: '../../assets/img/boat/boat7-small.JPG',
        medium: '../../assets/img/boat/boat7-medium.JPG',
        big: '../../assets/img/boat/boat7.JPG'
      },
      {
        small: '../../assets/img/boat/boat8-small.JPG',
        medium: '../../assets/img/boat/boat8-medium.JPG',
        big: '../../assets/img/boat/boat8.JPG'
      },
      {
        small: '../../assets/img/boat/boat9-small.JPG',
        medium: '../../assets/img/boat/boat9-medium.JPG',
        big: '../../assets/img/boat/boat9.JPG'
      },
      {
        small: '../../assets/img/boat/boat10-small.JPG',
        medium: '../../assets/img/boat/boat10-medium.JPG',
        big: '../../assets/img/boat/boat10.JPG'
      }
    ];
  }
}
