<header>
  <app-navigation-component (switchLangEvent)="switchLang($event)"></app-navigation-component>
</header>
  <div id="page" class="container-fluid">
    <div id="columns_container">
      <div id="columns" class="">
        <div id="center_column" class="row">
            <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-footer-component id="footer"></app-footer-component>
