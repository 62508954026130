import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import {languageEnum, langUtils} from "./utils/langUtils";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs([languageEnum.FRANCAIS, languageEnum.ANGLAIS]);
    translate.setDefaultLang(langUtils.getDefaultLanguage());
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
