import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {PrestationComplete} from "../model/element.model";
import {BackendApiService} from "../services/backend-api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-formules',
  templateUrl: './formules.component.html',
  styleUrls: ['./formules.component.css']
})
export class FormulesComponent implements OnInit, AfterViewChecked {

  balades$: Observable<PrestationComplete[]>;
  anchor: string;

  constructor(private backEndService: BackendApiService, private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      this.anchor = fragment;
    });
  }

  ngOnInit(): void {

    this.balades$ = this.backEndService.getBalades().pipe(map((res: any) => {
      const balades: PrestationComplete[] = [];
      for(let balade of res.balades) {
        balade.carousel = [
          {id: 1, titre: balade.titre, alt: balade.alt, href: 'balades'},
        ];
        if (balade.hasCircuit) {
          balade.carousel.push({id: 2, titre: balade.titre + 'Carte', alt: balade.alt + 'Carte', href: 'balades'});
        }
        if (balade.titre === 'TitreLibre') {
          balade.carousel.push({id: 2, titre: 'LibrePrivatisation', alt: balade.alt + 'P1', href: 'balades'});
          balade.carousel.push({id: 3, titre: 'LibrePrivatisation2', alt: balade.alt + 'P2', href: 'balades'});
        } else if (balade.titre === 'TitreInevitable') {
		      balade.carousel.push({id: 3, titre: 'TitreInevitable2', alt: balade.alt + 'P2', href: 'balades'});
		    }
        balades.push(balade);
      }
      return balades;
    }));
  }

  ngAfterViewChecked(): void {
    this.anchor ? document.getElementById(this.anchor).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}) : '';
  }
}
