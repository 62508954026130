<div class="footer">
  <div class="container-fluid">
    <div class="footer_top row">
      <div class="container">


        <div class="col-xs-12 col-md-6">
          <h4 class="title_block">{{'ADecouvrir' | translate}}</h4>
          <ul>
            <li>
              <a class="item" rel="noopener" title="Les villages du Cap" target="_blank" href="https://www.my-capferret.com/villages-cap-ferret/">{{'VillageCap' | translate}}</a>

            </li>
            <li>
              <a class="item" rel="noopener" title="L'office du tourisme" target="_blank" href="https://www.lege-capferret.com/info-pratique/office-tourisme">{{'OfficeTourisme' | translate}}</a>

            </li>
            <li>
              <a class="item" title="Carte " href="/boat#cartes">{{'Carte' | translate}}</a>

            </li>
            <li>
              <a class="item" rel="noopener" title="L'ile aux oiseaux" target="_blank" href="https://www.arcachon.com/tourisme/je-decouvre/lieux-dexception/lile-aux-oiseaux/">{{'Lileauxoiseaux' | translate}}</a>
            </li>
          </ul>
        </div>

        <div class="col-xs-12 col-md-6">
          <h4 class="title_block">{{'Infospratiques' | translate}}</h4>
          <ul>
            <li>
              <a class="item" title="Mentions Légales" href="/mentions-legales">{{'Mentionslégales' | translate}}</a>

            </li>
            <li>
              <a class="item" title="Plan d'accès à l'embarcadère" href="/plan-d-acces">{{'PlanEmbarcadere' | translate}}</a>

            </li>
            <li>
              <a class="item" title="Conditions Générales de Ventes" href="/cgv">{{'Conditionsventes' | translate}}</a>

            </li>
          </ul>
        </div>

      </div>
    </div>

    <div class="footer_bottom row">
      <div class="container">
        <div id="">
          <p class="text-center"><span class="contact_tel">Contact&nbsp;:&nbsp;+33&nbsp;(0)6.09.06.45.79</span>&nbsp;|&nbsp;Siège social, 49 Allée du rivage - 33950 Lège-Cap-Ferret | Embarcadère de Grand Piquey - Rue Louis Gaume - 33950 Lège-Cap-Ferret - SIREN 890467855 - © 2021 - Cap Ferret Cruise Confort Company - Tous droits réservés</p>
        </div>
      </div>
    </div>
  </div>
</div>
