import {Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl} from '@angular/forms';

import {BackendApiService} from "../services/backend-api.service";
import {Subject} from "rxjs";
import {MailReservation, Reservation, TitreOption, TitrePrestation} from "../model/element.model";

@Component({
  selector: 'app-paiement-stripe',
  templateUrl: './paiements-stripe.component.html',
  styleUrls: ['./paiements-stripe.component.css']
})
export class PaiementsStripeComponent implements OnInit, OnDestroy {
  @Input() totalAPayer: number;
  @Input() totalPrestation: number;
  @Input() totalOption: number;
  @Input() reservationDetail: Reservation;

  destroy$: Subject<boolean> = new Subject<boolean>();

  cardName = new UntypedFormControl('', [Validators.required]);
  cardEmail = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]);
  cardTelephone = new UntypedFormControl('', [Validators.required]);
  errorMessage = '';
  isError: boolean;
  succeed: boolean;
  disabledSubmitButton: boolean;

 /* cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'fr',
  };*/
  stripe: UntypedFormGroup;

  constructor(
    private backendService: BackendApiService,
    private fb: UntypedFormBuilder) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.stripe = this.fb.group({
      name: this.cardName,
      email: this.cardEmail,
      telephone: this.cardTelephone
    });
  }

  pay(): void {
    /*this.disabledSubmitButton = true;
    this.succeed = false;
    this.isError = false;
    if (this.stripe.valid) {
      this.createPaymentIntent(this.cardName.value, this.cardEmail.value, this.cardTelephone.value)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card.element,
                billing_details: {
                  name: this.cardName.value,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          if (result.error) {
            this.isError = true;
            this.errorMessage = result.error.message;
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              const emailReservation: MailReservation = this.initEmailReservation();
              this.backendService.sendMailReservation(emailReservation).pipe(takeUntil(this.destroy$)).subscribe(
                res => {
                  this.isError = false;
                  this.succeed = true;
                },
                (e) => {
                  this.isError = true;
                  this.disabledSubmitButton = false;
                  this.errorMessage = 'Une erreur est survenue avec l\'envoi de mail. Cependant la réservation a bien été prise en compte. Vous pouvez confirmer par téléphone au 06.09.06.45.79';
                }
                );
            }
          }
        },
          (err) => {
            this.isError = true;
            this.disabledSubmitButton = false;
            this.errorMessage = 'La date choisie semble avoir été réservée et ne plus être disponible, l\'opération a bien été annulée, vous ne serez pas débité';
          });
    } else {
      this.disabledSubmitButton = false;
      this.isError = true;
      console.log(this.stripe);
      this.errorMessage = 'Impossible de valider avec ces informations';
    }*/
  }

  /*createPaymentIntent(name: string, email: string, telephone: string): Observable<PaymentIntent> {
    return this.backendService.payment(this.reservationDetail, {name, email, telephone});
  }*/

  initEmailReservation(): MailReservation {
    let listOptions = '';
    for(let option of this.reservationDetail.optionsSupplementaires) {
      if(listOptions !== '') {
        listOptions = listOptions + ', '
      }
      listOptions = listOptions + `${option.nbOption} ${TitreOption[option.idOption]}`;
    }
    return {
      ...this.reservationDetail,
      user: this.cardName.value,
      mail: this.cardEmail.value,
      telephone: this.cardTelephone.value,
      titrePrestation: TitrePrestation[this.reservationDetail.idPrestation],
      tarifPrestation: this.totalPrestation.toString(),
      listOption: listOptions,
      tarifOption: this.totalOption.toString(),
      prixTotal: this.totalAPayer.toString(),
    }
  }

}
