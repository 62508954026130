<div class="container encart" *ngIf="balades$ | async as balades">
  <h1 class="h1 titre-encart">
    {{"prestations.Titre" | translate}}
  </h1>

  <div id="TitreInevitable" class="anchor">
    <app-cadre-prestations [prestation]="balades[0]" [type]="'balades'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreExquise" class="anchor">
    <app-cadre-prestations [prestation]="balades[1]" [type]="'balades'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreLibre" class="anchor">
    <app-cadre-prestations [prestation]="balades[2]" [type]="'balades'"></app-cadre-prestations>
  </div>
  <hr/>
  <div id="TitreSunset" class="anchor">
    <app-cadre-prestations [prestation]="balades[3]" [type]="'balades'"></app-cadre-prestations>
  </div>
  <hr/>
<div class="">
  <div class="contact-events">{{'options.Contact' | translate}}</div>
</div>
</div>
