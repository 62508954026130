import {Component, Input} from '@angular/core';
import {ListeElements} from "../model/element.model";

@Component({
  selector: 'app-cadre-options',
  templateUrl: './cadre-options.component.html'
})
export class CadreOptionsComponent {

  @Input() option: ListeElements;

}
