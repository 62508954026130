import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {ListeElements} from "../model/element.model";
import {BackendApiService} from "../services/backend-api.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit, AfterViewChecked {

  options$: Observable<ListeElements[]>;
  anchor: string;

  constructor(private backEndService: BackendApiService, private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      this.anchor = fragment;
    });
  }

  ngOnInit(): void {
    this.options$ = this.backEndService.getOptions().pipe(map((res: any) => {
      for(let option of res.options) {
        option.description = "Description" + option.titre;
      }
      res.options[0].informationsComplementaires = "Inclus";
      return res.options;
    }));
  }

  ngAfterViewChecked(): void {
    this.anchor ? document.getElementById(this.anchor).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}) : '';
  }
}
