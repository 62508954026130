import {AfterViewInit, Component} from '@angular/core';
import * as L from 'leaflet'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterViewInit {

  map;
  marker;

  constructor() { }

  ngAfterViewInit(): void {
    this.createMap();
  }

  createMap() {
    const embarcadere = {
      lat: 44.71429,
      lng: -1.21174
    };

    const zoomLevel = 15;

    const myIcon = new L.Icon({
      iconUrl: '../../assets/img/marker-icon-red.png',
      shadowUrl: '../../assets/img/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });

    this.map = L.map('map', {
      center: [embarcadere.lat, embarcadere.lng],
      zoom: zoomLevel
    });

    this.marker = L.marker([embarcadere.lat, embarcadere.lng], {icon: myIcon}).addTo(this.map);

    const mainLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 9,
      maxZoom: 17,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    mainLayer.addTo(this.map);

  }

}
