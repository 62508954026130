import {Component, Input} from '@angular/core';
import {PrestationComplete} from "../model/element.model";

@Component({
  selector: 'app-cadre-prestations',
  templateUrl: './cadre-prestations.component.html'
})
export class CadrePrestationsComponent {

  @Input() prestation: PrestationComplete
  @Input() type = '';

}
