import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {PrestationComplete} from "../model/element.model";
import {BackendApiService} from "../services/backend-api.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html'
})
export class EventsComponent implements OnInit, AfterViewChecked {
  events$: Observable<PrestationComplete[]>;
  anchor: string;

  constructor(private backEndService: BackendApiService, private route: ActivatedRoute) {
    this.route.fragment.subscribe((fragment: string) => {
      this.anchor = fragment;
    });
  }

  ngOnInit(): void {
    this.events$ = this.backEndService.getEvents().pipe(map((res: any) => {
      const events: PrestationComplete[] = [];
      for(let event of res.events) {
        event.carousel = [
          {id: 1, titre: event.titre, alt: event.alt, href: 'events'}
        ];
        if (event.hasCircuit) {
          event.carousel.push({id: 2, titre: event.titre + 'Carte', alt: event.alt + 'Carte', href: 'events'})
        }

  		  if (event.titre === 'TitreStudieuse') {
	  		  event.carousel.push({id: 2, titre: 'TitreStudieuse2', alt: event.alt + 'P1', href: 'balades'});
		    }
        events.push(event);
      }

      return events;
    }));
  }

  ngAfterViewChecked(): void {
    this.anchor ? document.getElementById(this.anchor).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}) : '';
  }
}
