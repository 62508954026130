<div class='container-fluid'>

  <div class="wrapper">
    <owl-carousel-o [options]="customOptions">

      <ng-container *ngFor="let el of carouselElement">
        <ng-template class="slide" carouselSlide>
          <span class="carousel-caption-img">
              <a href='{{"/" + el.href + "#" + el.titre}}'>
                <img src='{{ "../../assets/img/" + el.titre + ".jpg" }}' [alt]="el.alt" title="{{ 'titres.' + el.titre | translate}}" class="owl-item">
                <span class="carousel-caption-custom">

                  <span class="ann-titre">
                      <h4>{{ "titres." + el.titre | translate}}</h4>
                  </span>

                </span>
              </a>
            </span>
        </ng-template>
      </ng-container>

    </owl-carousel-o>
  </div>

</div>
