import { Component, OnInit } from '@angular/core';
import {CarouselElement} from "../model/element.model";
import {BackendApiService} from "../services/backend-api.service";
import {forkJoin, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {GcpService} from "../services/gcp.service";

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  title = 'Cap-Ferret Cruise Confort Company - Grand Piquey';
  backendService$: Observable<[CarouselElement[],CarouselElement[],CarouselElement[]]>;
  ferme: boolean;

  constructor(private backEndService: BackendApiService, private gcpService: GcpService) {
    this.ferme = gcpService.isFerme();
  }

  ngOnInit(): void {
    const balades$ = this.backEndService.getBalades().pipe(map(
      (res: any) =>
      {
        const balades: CarouselElement[] = [];
        let id = 1;
        for (let balade of res.balades) {
          balades.push ({id, titre: balade.titre, alt: balade.alt, href: 'balades' });
          id = id + 1;
        }
        return balades;
      }
    ));
    const events$ = this.backEndService.getEvents().pipe(map(
      (res: any) => {
        const events: CarouselElement[] = [];
        let id = 1;
        for (let event of res.events) {
          events.push ({id, titre: event.titre, alt: event.alt, href: 'evenements' });
          id = id + 1;
        }
        return events;
      }
    ));
    const options$ = this.backEndService.getOptions().pipe(map(
      (res: any) => {
        const options: CarouselElement[] = [];
        let id = 1;
        for (let option of res.options) {
          options.push({id, titre: option.titre, alt: option.titre, href: 'options'});
          id = id + 1;
        }
        return options;
      }
    ));
    this.backendService$ = forkJoin([balades$,events$,options$]);
  }
}
