import {Component, Input} from '@angular/core';
import {OwlOptions} from "ngx-owl-carousel-o";
import {CarouselElement} from "../model/element.model";

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent {

  @Input() carouselElement: CarouselElement[] = [];

  customOptions: OwlOptions = {
    loop: true,
    stagePadding: 50,
    margin: 10,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 100,
    autoplay: true,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      680: {
        items: 2
      }
    },
    nav: true
  };

}
