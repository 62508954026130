<div class="bannerAccueil"></div>
<div class="container">
  <div class="container encart">
    <h1 class="h1 titre-encart">
      {{"Accueil" | translate}}
    </h1>

    <div class="row">
      <h2 class="editorial-main-title"><strong [innerHTML]="'AccueilPremierTitre' | translate"></strong></h2>
      <h3 class="editorial-main-title" [innerHTML]="'AccueilSecondTitre' | translate"></h3>
	  </div>

    <!--<div class="row">
      <h3 class="editorial-main-title">
        <div style="color:#370028;"><strong [innerHTML]="'Ouverture' | translate"></strong></div>
        {{ 'Reservatons_disponibles' | translate }}<a href="reserver">{{ 'Online' | translate }}</a>, <a href="/contact">{{ 'Mail' | translate }}</a>{{ 'OrPhone' | translate }}06.09.06.45.79
      </h3>
    </div>-->
  </div>
</div>

<div class="carousels" *ngIf="backendService$ | async as elements; else loading">
  <div class="balade-accueil">
    <div class="container">
      <h3 style="color:white" [innerHTML]="'BaladesAccueil' | translate"></h3>
      <app-carousel [carouselElement]="elements[0]"></app-carousel>
    </div>
  </div>


  <div class="option-accueil">
    <div class="container">
      <h3 [innerHTML]="'DegustationsAccueil' | translate"></h3>
      <app-carousel [carouselElement]="elements[2]"></app-carousel>
    </div>
  </div>

  <div class="balade-accueil">
    <div class="container">
      <h3 style="color:white" [innerHTML]="'EvenementsAccueil' | translate"></h3>
      <app-carousel [carouselElement]="elements[1]"></app-carousel>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="balade-accueil">
    <div class="container">
      <h3 style="color:white" [innerHTML]="'BaladesAccueil' | translate"></h3>
      <ngx-ui-loader [loaderId]="'loader-balade'"></ngx-ui-loader>
    </div>
  </div>


  <div class="option-accueil">
    <div class="container">
      <h3 [innerHTML]="'DegustationsAccueil' | translate"></h3>
      <ngx-ui-loader [loaderId]="'loader-option'"></ngx-ui-loader>
    </div>
  </div>

  <div class="balade-accueil">
    <div class="container">
      <h3 style="color:white" [innerHTML]="'EvenementsAccueil' | translate"></h3>
      <ngx-ui-loader [loaderId]="'loader-event'"></ngx-ui-loader>
    </div>
  </div>
</ng-template>
