import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BackendApiService} from "../services/backend-api.service";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MailInformation, Reservation} from "../model/element.model";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnDestroy, OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  contactForm: UntypedFormGroup;
  labelControl = new UntypedFormControl('', [Validators.required]);
  emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  telephoneControl = new UntypedFormControl('');
  messageControl = new UntypedFormControl('', [Validators.required]);

  submitted = false;
  disabled=false;
  error = false;
  mailInformation: MailInformation = {};

  reservationInformation: Reservation;

  constructor(private backEndService: BackendApiService, private formBuilder: UntypedFormBuilder, private router: Router) {
    const routerExtras = this.router.getCurrentNavigation().extras;
    this.reservationInformation = routerExtras.state?.reservation || null;
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  onSubmit() {
    this.disabled = true;
    this.mailInformation = {
      user: this.labelControl.value,
      mail: this.emailControl.value,
      tel: this.telephoneControl.value,
      message: this.messageControl.value
    };
    this.backEndService.sendMail(this.mailInformation).pipe(takeUntil(this.destroy$)).subscribe(() =>
      {
        this.error = false;
        this.submitted = true;
      },
    () => {
      this.error = true;
      this.disabled = false;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      label: this.labelControl,
      email: this.emailControl,
      tel: this.telephoneControl,
      message: this.messageControl
    });
    if (this.reservationInformation) {
      let messageOptions = 'Aucune option supplémentaire choisie\n';
      let messageEnfant = '';
      let messageEnfant3ans = '';
      if (this.reservationInformation.optionsSupplementaires.length) {
        messageOptions = '';
        for(let option of this.reservationInformation.optionsSupplementaires) {
          messageOptions = messageOptions + `- ${option.nbOption} ${option.titreOption}\n`;
        }
      }
      const nbEnfantsTotaux = this.reservationInformation.nbEnfant + this.reservationInformation.nbEnfant3ans;
      if(nbEnfantsTotaux) {
        if(this.reservationInformation.nbEnfant3ans) {
          messageEnfant3ans = `(dont ${this.reservationInformation.nbEnfant3ans} de moins de 3 ans) `;
        }
        messageEnfant = `et ${nbEnfantsTotaux} enfant(s) ${messageEnfant3ans}`;
      }
      let messageReservation = `Bonjour,\n\nJe souhaiterais avoir un devis à propos de votre formules ${this.reservationInformation.titrePrestation}.\n\nNous serions ${this.reservationInformation.nbAdulte} adulte(s) ${messageEnfant}et souhaiterions réserver pour le ${this.reservationInformation.date}.\n\nNos options souhaitées :\n`;
      let messageFin = `\nMerci d'avance pour votre retour.\nCordialement`
      messageReservation = messageReservation + messageOptions + messageFin;


      this.messageControl.setValue(messageReservation);
    }
  }

  getMessage(formControl: UntypedFormControl) {
    if (formControl.hasError('required')) {
      return 'error.required';
    }
    if (formControl.hasError('email')) {
      return 'error.email';
    }
  }
}
